import {
  Sidebar,
  Header,
  FolderList,
  FolderItem,
  Container,
  WrapperProfile,
  Username,
  RecentChatItem,
  RecentChats,
  Logo,
} from "./styled";
import { ReactComponent as SideBarIcon } from "../../assets/sidebar.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";
import { ReactComponent as RecentIcon } from "../../assets/recent.svg";
import { ReactComponent as SettingIcon } from "../../assets/setting.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as ProfileIcon } from "../../assets/user.svg";
import defaultAvatar from "../../assets/default_avatar.png";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { getHistoryThunk } from "../../store/actions/chat.action";
import { useTopic } from "../../context/topicContext";
import { topics } from "../../constants/topics";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/actions/auth.action";
import logo from "../../assets/logo.png";
import { Tooltip, Menu, Dropdown } from "antd";

const SidebarComponent = () => {
  const dispatch = useAppDispatch(),
    navigate = useNavigate();
  const features = [
    {
      name: "Booking Hotel",
      icon: <CalendarIcon width={22} height={22} />,
      expandDisable: false,
      func: () => {
        navigate("booking");
      },
    },
    {
      name: "New Chat",
      icon: <AddIcon width={22} height={22} />,
      expandDisable: false,
      func: () => {
        navigate(`${topics.find((t) => t.id === parseInt(topic))?.route}`);
      },
      topicRequired: true,
    },
    {
      name: "Recents",
      icon: <RecentIcon width={22} height={22} />,
      expandDisable: true,
      func: () => toggleSidebar(),
      topicRequired: true,
    },
  ];
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { me } = useAppSelector((state) => state.authReducer);
  const { recentsChat } = useAppSelector((state) => state.chatReducer);
  const { topic, setTopic } = useTopic();

  const fecthRecentSearch = async () => {
    if (!topic) return;

    try {
      await dispatch(
        getHistoryThunk({ bookingType: parseInt(topic), page: 1 })
      ).unwrap();
    } catch (e) {}
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async () => {
    setTopic("");
    await dispatch(logout());
  };

  const menuItems = [
    {
      key: "profile",
      label: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "white",
          }}
        >
          <ProfileIcon width={16} height={16} />
          My Profile
        </span>
      ),
      onClick: () => navigate("/profile"),
    },
    {
      key: "logout",
      label: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "white",
          }}
        >
          <LogoutIcon width={16} height={16} />
          Logout
        </span>
      ),
      onClick: handleLogout,
    },
  ];

  useEffect(() => {
    fecthRecentSearch();
  }, [topic]);

  return (
    <Container isOpen={isSidebarOpen}>
      <Sidebar>
        <Header isOpen={isSidebarOpen}>
          {isSidebarOpen && (
            <Logo src={logo} height={64} onClick={() => navigate("/")} />
          )}
          <Tooltip
            title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
            placement="right"
          >
            <SideBarIcon width={22} onClick={toggleSidebar} />
          </Tooltip>
        </Header>

        {topic && (
          <FolderList isOpen={isSidebarOpen}>
            {features
              .filter((folder) => !folder.topicRequired || topic)
              .map((folder, index) => (
                <Tooltip
                  key={index}
                  title={!isSidebarOpen ? folder.name : undefined}
                  placement="right"
                >
                  <FolderItem
                    onClick={folder.func}
                    expandDisable={folder.expandDisable && isSidebarOpen}
                  >
                    {isSidebarOpen ? folder.name : folder.icon}
                  </FolderItem>
                </Tooltip>
              ))}
            {isSidebarOpen && Object.keys(recentsChat).length > 0 && (
              <div
                style={{ gap: "8px", display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    color: "#a1a1aa",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Recent Chats
                </div>
                <RecentChats>
                  {recentsChat.items.map((item) => (
                    <RecentChatItem
                      to={`${
                        topics.find((t) => t.id === parseInt(topic))?.route
                      }/${item._id}`}
                    >
                      {item.title}
                    </RecentChatItem>
                  ))}
                </RecentChats>
              </div>
            )}
          </FolderList>
        )}
        {!topic && (
          <FolderList isOpen={isSidebarOpen}>
            {features
              .filter((folder) => !folder.topicRequired)
              .map((folder, index) => (
                <FolderItem
                  onClick={folder.func}
                  key={index}
                  expandDisable={folder.expandDisable && isSidebarOpen}
                >
                  {isSidebarOpen ? folder.name : folder.icon}
                </FolderItem>
              ))}
          </FolderList>
        )}
        <WrapperProfile isOpen={isSidebarOpen}>
          <Tooltip title="My Profile" placement="right">
            <img
              style={{ width: 40, height: 40, cursor: "pointer" }}
              src={defaultAvatar}
              onClick={() => navigate("/profile")}
            />
          </Tooltip>
          <Username>
            {me?.firstName} {me?.lastName}
          </Username>
          <Dropdown
            menu={{
              items: menuItems,
              style: {
                background: "#1a1a1a",
              },
            }}
            placement="topRight"
            trigger={["click"]}
          >
            <SettingIcon style={{ cursor: "pointer" }} width={20} />
          </Dropdown>
        </WrapperProfile>
      </Sidebar>
    </Container>
  );
};

export default SidebarComponent;
