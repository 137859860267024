import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store";
import SubscriptionForm from "../../containers/Booking/SubscriptionForm";
import { MotionWrapper } from "../../containers/Apps/styled";
import {
  getUserInfoThunk,
  uploadAvatarThunk,
} from "../../store/actions/auth.action";
import { getHistoryThunk } from "../../store/actions/chat.action";
import dayjs from "dayjs";
import defaultAvatar from "../../assets/default_avatar.png";
import { listCountries } from "../../constants/countries";
import { message } from "antd";
import { getBookingHistoryThunk } from "../../store/actions/booking.action";
import { deleteSubscriptionThunk } from "../../store/actions/subscription.action";
const SubscriptionPage = () => {
  const dispatch = useAppDispatch();
  const { me, userPayment, userExtendData } = useAppSelector(
    (state) => state.authReducer
  );
  const [bookingHistory, setBookingHistory] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const getCountryLabel = (countryCode?: string) => {
    if (!countryCode) return "Not specified";
    const country = listCountries.find(
      (c) => c.value === countryCode.toUpperCase()
    );
    return country?.label || countryCode;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getUserInfoThunk()).unwrap();
        await fetchBookingHistory();
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };
    fetchData();
  }, []);

  const fetchBookingHistory = async () => {
    try {
      const response = await dispatch(getBookingHistoryThunk()).unwrap();
      console.log("response", response);
      setBookingHistory(response || []);
    } catch (error) {
      console.error("Failed to fetch booking history:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await dispatch(deleteSubscriptionThunk()).unwrap();
      setShowCancelModal(false);
      await dispatch(getUserInfoThunk()).unwrap();
      message.success("Subscription cancelled successfully");
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      message.error("Failed to cancel subscription");
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", file);

      await dispatch(uploadAvatarThunk({ data: formData })).unwrap();
      await dispatch(getUserInfoThunk()).unwrap();

      message.success("Avatar updated successfully");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <MotionWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Container>
        {/* Profile Section */}
        <ProfileWrapper>
          <AvatarContainer onClick={handleAvatarClick}>
            <img
              src={userExtendData?.avatarUrl || defaultAvatar}
              alt="avatar"
            />
            <AvatarOverlay>
              <OverlayIcon>📷</OverlayIcon>
              <OverlayText>Change Photo</OverlayText>
            </AvatarOverlay>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
              style={{ display: "none" }}
            />
          </AvatarContainer>
          <ProfileSection>
            <UserName>
              {me?.firstName} {me?.lastName}
            </UserName>
            <UserEmail>{me?.email}</UserEmail>
            <UserInfoGrid>
              <InfoItem>
                <InfoIcon>📍</InfoIcon>
                <InfoLabel>Location</InfoLabel>
                <InfoValue>{getCountryLabel(me?.country)}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoIcon>📱</InfoIcon>
                <InfoLabel>Phone</InfoLabel>
                <InfoValue>{me?.phone || "Not provided"}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoIcon>🌟</InfoIcon>
                <InfoLabel>Member Status</InfoLabel>
                <InfoValue>
                  {me?.paymentMode === 4 ? "Executive" : "Basic"}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoIcon>📅</InfoIcon>
                <InfoLabel>Member Since</InfoLabel>
                <InfoValue>
                  {me?.createdAt
                    ? dayjs(me.createdAt).format("MMM YYYY")
                    : "Not available"}
                </InfoValue>
              </InfoItem>
            </UserInfoGrid>
          </ProfileSection>
        </ProfileWrapper>
        {/* Subscription Section */}
        <Section>
          <SectionHeader>
            <Title>My Subscription</Title>
            {me?.paymentMode === 4 && (
              <SubscriptionStatus>
                <StatusText>
                  {userPayment?.unsubscribedDate
                    ? `Expires on ${dayjs(userPayment.unsubscribedDate).format(
                        "MMM DD, YYYY"
                      )}`
                    : `Renews on ${dayjs(
                        userPayment?.endDate || new Date()
                      ).format("MMM DD, YYYY")}`}
                </StatusText>
              </SubscriptionStatus>
            )}
          </SectionHeader>
          <Content>
            {me?.paymentMode === 4 ? (
              <>
                <ActiveSubscription>
                  <BenefitSection>
                    <SectionTitle>Your Benefits</SectionTitle>
                    <BenefitsList>
                      <BenefitItem>
                        <CheckIcon>✓</CheckIcon>
                        Unlimited 5-Star Hotel Special Rate, Available
                        Year-Round
                      </BenefitItem>
                      <BenefitItem>
                        <CheckIcon>✓</CheckIcon>
                        Points accrual through existing hotel membership
                        programs
                      </BenefitItem>
                      <BenefitItem>
                        <CheckIcon>✓</CheckIcon>
                        Exclusive program and hotel event invitations
                      </BenefitItem>
                      <BenefitItem>
                        <CheckIcon>✓</CheckIcon>
                        All premium services provided by KardoAI
                      </BenefitItem>
                      <BenefitItem>
                        <CheckIcon>✓</CheckIcon>
                        Hotel Wishlist Available
                      </BenefitItem>
                    </BenefitsList>
                  </BenefitSection>
                  <PlanDetails>
                    <DetailItem>
                      <DetailLabel>Plan</DetailLabel>
                      <DetailValue>Executive Tier</DetailValue>
                    </DetailItem>
                    <DetailItem>
                      <DetailLabel>Price</DetailLabel>
                      <DetailValue>$499.99/year</DetailValue>
                    </DetailItem>
                    <DetailItem>
                      <DetailLabel>Status</DetailLabel>
                      <DetailValue>Active</DetailValue>
                    </DetailItem>
                    {!userPayment?.unsubscribedDate ? (
                      <CancelButton onClick={() => setShowCancelModal(true)}>
                        Cancel Subscription
                      </CancelButton>
                    ) : (
                      <CanceledButton>Canceled</CanceledButton>
                    )}
                  </PlanDetails>
                </ActiveSubscription>

                {/* Cancel Subscription Modal */}
                {showCancelModal && (
                  <ModalOverlay>
                    <Modal>
                      <ModalHeader>Cancel Subscription</ModalHeader>
                      <ModalContent>
                        <WarningIcon>👋</WarningIcon>
                        <ModalText>
                          We're sorry to see you go! Are you sure you want to
                          cancel your Executive Tier subscription?
                        </ModalText>
                        <InfoList>
                          <ModalInfoItem>
                            Your subscription will remain active until the end
                            of your current billing period
                          </ModalInfoItem>
                          <ModalInfoItem>
                            You will lose access to all Executive Tier benefits
                            after this period
                          </ModalInfoItem>
                          <ModalInfoItem>
                            Any existing hotel bookings will not be affected
                          </ModalInfoItem>
                        </InfoList>
                      </ModalContent>
                      <ModalActions>
                        <SecondaryButton
                          onClick={() => setShowCancelModal(false)}
                        >
                          Keep Subscription
                        </SecondaryButton>
                        <DangerButton onClick={handleCancelSubscription}>
                          Confirm Cancellation
                        </DangerButton>
                      </ModalActions>
                    </Modal>
                  </ModalOverlay>
                )}
              </>
            ) : (
              <SubscriptionForm />
            )}
          </Content>
        </Section>

        {/* Booking History Section */}
        <Section>
          <SectionHeader>
            <Title>Booking History</Title>
          </SectionHeader>
          <BookingHistoryContent>
            {bookingHistory.length > 0 ? (
              <BookingList>
                {bookingHistory.map((booking: any, index) => (
                  <BookingCard key={index}>
                    <BookingHeader>
                      <HotelName>{booking.hotelName || "Hilton"}</HotelName>
                    </BookingHeader>
                    <BookingDetails>
                      <BookingDetail>
                        <DetailIcon>📅</DetailIcon>
                        <span>
                          {dayjs(booking.from).format("MMM DD")} -{" "}
                          {dayjs(booking.to).format("MMM DD, YYYY")}
                        </span>
                      </BookingDetail>
                      <BookingDetail>
                        <DetailIcon>📍</DetailIcon>
                        <span>
                          {booking.city +
                            ", " +
                            getCountryLabel(booking.location)}
                        </span>
                      </BookingDetail>
                      {booking.roomType && (
                        <BookingDetail>
                          <DetailIcon>🛏️</DetailIcon>
                          <span>{booking.roomType}</span>
                        </BookingDetail>
                      )}
                    </BookingDetails>
                  </BookingCard>
                ))}
              </BookingList>
            ) : (
              <EmptyState>No booking history found</EmptyState>
            )}
          </BookingHistoryContent>
        </Section>
      </Container>
    </MotionWrapper>
  );
};
const ProfileWrapper = styled.div`
  position: relative;
`;

const UserName = styled.h1`
  font-size: 1.75rem;
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 2rem 2rem 0.75rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding: 1.5rem 1.5rem 0.5rem;
  }
`;

const UserEmail = styled.p`
  color: #a1a1aa;
  text-align: center;
  margin: 0;
  font-size: 0.875rem;
`;

const UserInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  padding: 2rem;
  margin-top: 1.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    padding: 1.5rem;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const InfoIcon = styled.span`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
`;

const InfoLabel = styled.span`
  color: #a1a1aa;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
`;

const InfoValue = styled.span`
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
`;

const OverlayIcon = styled.span`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const OverlayText = styled.span`
  font-size: 0.875rem;
  color: white;
`;

const AvatarContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  top: 0px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s;

  img {
    border-radius: 50%;
    border: 6px solid #202020;
    width: 150px;
    height: 150px;
    object-fit: cover;
    display: block;
  }

  @media (max-width: 768px) {
    img {
      width: 140px;
      height: 140px;
      border-width: 4px;
    }
  }

  &:hover {
    img {
      filter: brightness(0.7);
    }
    ${AvatarOverlay} {
      opacity: 1;
    }
  }
`;

const ProfileSection = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  overflow: visible;
  position: relative;
  padding-top: 80px;
  margin-top: 70px;

  @media (max-width: 768px) {
    padding-top: 80px;
    margin-top: 70px;
  }
`;

const Container = styled.div`
  width: 50%;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 1600px) {
    width: 60%;
  }

  @media (max-width: 1300px) {
    width: 75%;
  }

  @media (max-width: 1080px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 0 1rem;
  }
`;

const Section = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  overflow: visible;
  position: relative;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;

  @media (max-width: 768px) {
    padding: 1.25rem 1.5rem;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  img {
    position: absolute;
    left: 2rem;
    top: -100px;
    border-radius: 50%;
    overflow: hidden;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0;

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 1.25rem;
  }
`;

const SubscriptionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StatusText = styled.span`
  font-size: 1rem;
  color: #a1a1aa;
`;

const Content = styled.div`
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const ActiveSubscription = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const BenefitSection = styled.div``;

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0 0 1.5rem 0;
`;

const BenefitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  color: #e4e4e7;
`;

const CheckIcon = styled.span`
  color: #4caf50;
  font-weight: bold;
`;

const PlanDetails = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  gap: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailLabel = styled.span`
  color: #a1a1aa;
`;

const DetailValue = styled.span`
  font-weight: 500;
`;

const BookingHistoryContent = styled.div`
  padding: 2rem;
`;

const BookingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BookingCard = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  /* border: 1px solid rgba(255, 255, 255, 0.08); */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const BookingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

const HotelName = styled.h4`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  word-break: break-word;

  &::before {
    content: "🏨";
    font-size: 1.5rem;
    flex-shrink: 0;
  }
`;

const BookingDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
`;

const BookingDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #e4e4e7;
  font-size: 0.9375rem;
  padding: 0.875rem;
  background: rgba(255, 255, 255, 0.02);
  /* border: 1px solid rgba(255, 255, 255, 0.05); */
  border-radius: 8px;
  word-break: break-word;
  transition: all 0.2s;
`;

const DetailIcon = styled.span`
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  /* background: rgba(255, 159, 67, 0.1);
  border: 1px solid rgba(255, 159, 67, 0.15); */
  border-radius: 6px;
  flex-shrink: 0;
`;

const EmptyState = styled.div`
  text-align: center;
  color: #a1a1aa;
  padding: 3rem 0;
  font-size: 1rem;
`;

const CancelButton = styled.button`
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: transparent;
  border: 1px solid #ef4444;
  color: #ef4444;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(239, 68, 68, 0.1);
  }
`;

const CanceledButton = styled.button`
  width: 100%;
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: transparent;
  border: 1px solid #ef4444;
  color: #ef4444;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
`;

const Modal = styled.div`
  background: linear-gradient(180deg, #2a2a2a 0%, #1f1f1f 100%);
  border-radius: 24px;
  width: 90%;
  max-width: 560px;
  padding: 2.5rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    width: 95%;
    padding: 1.5rem;
    border-radius: 16px;
  }
`;

const ModalHeader = styled.h3`
  margin: 0 0 0.5rem 0;
  font-size: 1.75rem;
  text-align: center;
  font-weight: 600;
`;

const ModalContent = styled.div`
  margin: 1.5rem 0 2rem;
`;

const WarningIcon = styled.div`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  animation: wave 3s ease-in-out infinite;
  text-align: center;
  color: #ff9f43;

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(20deg);
    }
    75% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const ModalText = styled.p`
  font-size: 1.125rem;
  margin-bottom: 2rem;
  color: #e4e4e7;
  text-align: center;
  line-height: 1.5;
`;

const InfoList = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  border: 1px solid rgba(255, 255, 255, 0.05);
`;

const ModalInfoItem = styled.div`
  color: #a1a1aa;
  margin: 1rem 0;
  font-size: 0.9375rem;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
    justify-content: flex-start;
  }

  &::before {
    content: "•";
    color: #ff9f43;
    font-size: 1.25rem;
    line-height: 1;
  }
`;

const ModalActions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const Button = styled.button`
  padding: 0.875rem 2rem;
  border-radius: 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  min-width: 160px;

  @media (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    transform: translateY(0);
  }
`;

const DangerButton = styled(Button)`
  background: #ff6b6b;
  border: none;
  color: white;

  &:hover {
    box-shadow: 0 6px 16px rgba(255, 107, 107, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
`;

export default SubscriptionPage;
