import { createReducer } from "@reduxjs/toolkit";
import {
  getAuthUser,
  removeAcToken,
  removeRfToken,
  setAcToken,
  setRfToken,
} from "../../core/utils/local-storage";
import { IUserLogin } from "../../models/user.model";
import {
  loginThunk,
  logout,
  registerUserThunk,
  resendVerificationCodeThunk,
  verifyCodeThunk,
  loginWithGoogleThunk,
  getUserInfoThunk,
  uploadAvatarThunk,
  updateUserThunk,
} from "../actions/auth.action";

export interface AuthState {
  loading: boolean;
  accessToken?: string;
  refreshToken?: string;
  me?: IUserLogin;
  email: string;
  password: string;
  userPayment: any;
  userExtendData: any;
}

const initialState: AuthState = {
  loading: false,
  userPayment: null,
  userExtendData: null,
  me: getAuthUser(),
  email: "",
  password: "",
};

const AuthReducer = createReducer(initialState, (builder) => {
  builder.addCase(logout, (state) => {
    removeRfToken();
    removeAcToken();
    localStorage.removeItem("topic");
    state.me = undefined;
  });
  builder
    .addCase(loginThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(loginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.me = action.payload;
      setAcToken(action.payload.token);
      setRfToken(action.payload.refreshToken);
    })
    .addCase(loginThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getUserInfoThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUserInfoThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.me = action.payload.userData;
      state.userPayment = action.payload.payment;
      state.userExtendData = action.payload.userExtendData;
    })
    .addCase(getUserInfoThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(registerUserThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(registerUserThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(registerUserThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(verifyCodeThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(verifyCodeThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.me = action.payload;
      setAcToken(action.payload.token);
      setRfToken(action.payload.refreshToken);
    })
    .addCase(verifyCodeThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(resendVerificationCodeThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(resendVerificationCodeThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(resendVerificationCodeThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(loginWithGoogleThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(loginWithGoogleThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.me = action.payload as any;
      setAcToken((action.payload as any).token);
      setRfToken((action.payload as any).refreshToken);
    })
    .addCase(loginWithGoogleThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(uploadAvatarThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(uploadAvatarThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(uploadAvatarThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(updateUserThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(updateUserThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(updateUserThunk.rejected, (state) => {
      state.loading = false;
    });
});

export default AuthReducer;
