import { createReducer } from "@reduxjs/toolkit";
import { getAuthUser } from "../../core/utils/local-storage";
import { removeState, saveAT } from "../../services/localStorage.service";
// import { loginThunk, logout, verifyCode } from "../actions/auth.action";
import { ChatDto, NewChatDto, RecentChat } from "../../models/chat.model";
import {
  createQuestionThunk,
  getAnswerThunk,
  getDetailHistoryThunk,
  getHistoryThunk,
} from "../actions/chat.action";

export interface ChatState {
  recentsChat: {
    items: RecentChat[];
    total: number;
  };
  history: ChatDto[];
  newQuestion: NewChatDto;
  loading: boolean;
  error: any;
}

const initState = {
  history: [] as ChatDto[],
  recentsChat: {},
  newQuestion: {},
  loading: false,
  error: null,
} as ChatState;

const ChatReducer = createReducer(initState, (builder) => {
  builder
    .addCase(createQuestionThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(createQuestionThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.newQuestion = action.payload;
    })
    .addCase(createQuestionThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getHistoryThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getHistoryThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.recentsChat = action.payload;
      state.error = null;
    })
    .addCase(getHistoryThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
  builder
    .addCase(getDetailHistoryThunk.pending, (state) => {
      state.error = null;
    })
    .addCase(getDetailHistoryThunk.fulfilled, (state, action) => {
      state.history = action.payload.fullMsg;
      state.error = null;
    })
    .addCase(getDetailHistoryThunk.rejected, (state, action) => {
      state.error = action?.payload;
    });
  builder
    .addCase(getAnswerThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAnswerThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    })
    .addCase(getAnswerThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
});

export default ChatReducer;
