import { createReducer } from "@reduxjs/toolkit";
import {
  createBookingThunk,
  getBookingHistoryThunk,
} from "../actions/booking.action";

export interface BookingState {
  loading: boolean;
  bookingHistory: any[];
}

const initialState: BookingState = {
  loading: false,
  bookingHistory: [],
};

const BookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createBookingThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(createBookingThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createBookingThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getBookingHistoryThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getBookingHistoryThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.bookingHistory = action.payload;
    })
    .addCase(getBookingHistoryThunk.rejected, (state) => {
      state.loading = false;
    });
});

export default BookingReducer;
