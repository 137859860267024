/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as api from "../../services/booking.service";
import { BookingPayload } from "../../models/booking.model";

export const createBookingThunk = createAsyncThunk(
  "booking/createBooking",
  async (payload: BookingPayload, { rejectWithValue }) => {
    try {
      const response = await api.createBooking(payload);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const getBookingHistoryThunk = createAsyncThunk(
  "booking/getBookingHistory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.getBookingHistory();
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);