import { DatePicker, Select } from "antd";
import styled from "styled-components";

export const AuthContainer = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: #000;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: radial-gradient(
      circle at top right,
      rgba(0, 149, 255, 0.15),
      transparent 60%
    ),
    radial-gradient(
      circle at bottom left,
      rgba(0, 149, 255, 0.15),
      transparent 60%
    );
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthCard = styled.div`
  background-color: #242424;
  padding: 2.5rem 3.5rem;
  border-radius: 16px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
  }
`;

export const Title = styled.h1`
  color: white;
  text-align: center;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.p`
  color: #888;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  box-sizing: border-box;
`;

export const InputGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
`;

export const Label = styled.label`
  color: #fff;
  font-size: 0.875rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #333;
  border: 1px solid #404040;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #3a6df3;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
  }

  &::placeholder {
    color: #666;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #3a6df3;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2961f1;
  }

  &:disabled {
    background-color: #004400;
    cursor: not-allowed;
  }
`;

export const SocialButton = styled(Button)`
  background-color: #333;
  color: white;
  border: 1px solid #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  line-height: 20px;

  &:hover {
    background-color: #404040;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  color: #666;

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #404040;
  }
`;

export const Footer = styled.p`
  text-align: center;
  color: #666;
  margin-top: 1.5rem;

  a {
    color: #3a6df3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// export const Select1 = styled.select`
//   width: 100%;
//   padding: 0.75rem 1rem;
//   background-color: #333;
//   border: 1px solid #404040;
//   border-radius: 8px;
//   color: white;
//   font-size: 1rem;
//   box-sizing: border-box;

//   &:focus {
//     outline: none;
//     border-color: #3a6df3;
//     box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
//   }

//   option {
//     background-color: #242424;
//     color: white;
//   }
// `;

export const SDatePicker = styled(DatePicker)`
  background-color: #333 !important;
  border: 1px solid #404040;
  color: white !important;
  padding: 0.75rem 1rem;
  border-radius: 8px;

  &:focus {
    outline: none;
    border-color: #3a6df3;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
  }

  &:hover {
    border: 1px solid #404040;
  }

  span {
    color: rgb(255 255 255 / 25%) !important;
  }

  input: {
    color: white !important;
  }
`;

export const SSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  height: 44.89px !important;

  .ant-select-selector {
    background-color: #333 !important;
    border: 1px solid #404040 !important;
    color: white !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    padding: 0 1rem;
  }

  .ant-select-selection-item {
    color: white !important;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgb(255 255 255 / 25%) !important;
  }
`;
