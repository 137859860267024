import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { isMobile } from "../../core/utils/isMobile";
import SidebarComponent from "../../components/Sidebar";

const MainLayout = () => {
  return (
    <Container>
      <Wrapper>
        <SidebarComponent />
        <WrapperOutlet isMobile={isMobile()}>
          {/* <PageTransition> */}
          <Outlet />
          {/* </PageTransition> */}
        </WrapperOutlet>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #110f14;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: radial-gradient(
      circle at top right,
      rgba(0, 149, 255, 0.15),
      transparent 60%
    ),
    radial-gradient(
      circle at bottom left,
      rgba(0, 149, 255, 0.05),
      transparent 60%
    );
  flex-direction: row;
`;

const WrapperOutlet = styled.div<{ isMobile: boolean }>`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
`;

export default MainLayout;
