import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store";
import { searchAIHotelsThunk } from "../../store/actions/journey.action";
import { FilterWrapper, SSelect, WrapperResults } from "./styled";
import HotelCard from "./HotelItem";
import { Spin } from "antd";
import SearchComponent from "../../components/SearchInput";

type SortOption = {
  value: string;
  label: string;
};

const SortOptions = [
  { value: "random", label: "Sort By", invisible: true },
  { value: "lowest-points", label: "Lowest points" },
  { value: "highest-points", label: "Highest points" },
];

const FlightResults = ({ payload }: any) => {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<{
    resetAt: number;
    data: any[];
  }>({
    resetAt: Date.now(),
    data: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");

  const fetchAIHotel = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(
        searchAIHotelsThunk({
          fromDate: payload.ci,
          toDate: payload.co,
          brands: payload.brands.join(","),
          city: payload.city || "",
          country: payload.country || "",
          name: payload.name || null,
        })
      ).unwrap();
      if (res[0]?.r) {
        setResults({
          resetAt: Date.now(),
          data: res[0]?.r
            .filter((item: any) => item.pt !== undefined)
            .sort((a: any, b: any) => a.pt! - b.pt!),
        });
      } else {
        setResults({
          resetAt: Date.now(),
          data: [],
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortHotels = (item: SortOption) => {
    if (item.value === "lowest-points") {
      setResults((prev) => {
        const sortedHotels = prev.data.sort((a, b) => a.pt - b.pt);

        return {
          resetAt: Date.now(),
          data: sortedHotels,
        };
      });
    }

    if (item.value === "highest-points") {
      setResults((prev) => {
        const sortedHotels = prev.data.sort((a, b) => b.pt - a.pt);

        return {
          resetAt: Date.now(),
          data: sortedHotels,
        };
      });
    }
  };

  useEffect(() => {
    fetchAIHotel();
  }, [payload]);

  return (
    <WrapperResults>
      <FilterWrapper>
        <SearchComponent value={searchTerm} onChange={setSearchTerm} />
        <SSelect
          defaultValue={SortOptions[0]}
          options={SortOptions}
          onChange={(value: any) =>
            handleSortHotels(SortOptions.find((item) => item.value === value)!)
          }
          id="sort"
        />
      </FilterWrapper>
      {isLoading && <Spin />}
      {results.data?.map((hotel, index) => {
        if (
          !!searchTerm &&
          !hotel.n?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        ) {
          return <></>;
        }
        return <HotelCard key={index} data={hotel} />;
      })}
    </WrapperResults>
  );
};

export default FlightResults;
