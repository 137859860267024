import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? "280px" : "80px")};
  transition: width 0.3s ease-in-out;
  height: 100vh;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 100; */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;

  @media (max-width: 768px) {
    height: ${(props) => (props.isOpen ? "100vh" : "80px")};
    border-right: ${(props) => !props.isOpen && "none"};
    position: ${(props) => (props.isOpen ? "unset" : "absolute")};
    top: 0;
    left: 0;
  }
`;

export const Sidebar = styled.div`
  background-color: transparent;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`

// export const Sidebar = styled.div`
//   /* background-color: #110f14; */
//   background-color: transparent;
//   box-sizing: border-box;
//   flex: 1;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: 0 20px;
//   overflow: hidden;
// `;

export const Header = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: ${(props) => (props.isOpen ? "space-between" : "center")};
  height: 80px;
`;

export const Logo = styled.img`
  height: 24px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  background-color: #333;
  border: none;
  border-radius: 6px;
  color: white;
  margin: 1rem 0;
`;

export const FolderList = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
  }
`;

export const FolderItem = styled.div<{ expandDisable: boolean }>`
  white-space: nowrap;
  display: ${(props) => (props.expandDisable ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  gap: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
  line-height: 22px;

  &:hover {
    background-color: #27272a;
  }
`;

export const WrapperProfile = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
  }
`;

export const Username = styled.p`
  margin: 0;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  flex: 1;
  font-size: 16px;
`;

export const RecentChatItem = styled(Link)`
  margin: 0;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  flex-shrink: 0;

  &:hover {
    background-color: #27272a;
  }
`;

export const RecentChats = styled.div`
  margin: 0 -8px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow-y: auto;
  height: calc(100vh - 225px - 6rem);
  /* flex: 1; */
  padding-right: 4px;

  /* Hide default scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1924; /* Track color */
    border: 1px solid #242424;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1b2554; /* Thumb color */
    border-radius: 10px; /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #243b8d; /* Color on hover */
  }

  /* Optional: Add a custom arrow */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Corner color */
  }
`;
