import { hotelCountries } from "../constants/hotelCountries";

interface DateRange {
  start: { month: number; date: number };
  end: { month: number; date: number };
}

interface PriceRange extends DateRange {
  price: string;
}

interface RoomType {
  value: string;
  label: string;
  applyDate: PriceRange[];
}

interface Hotel {
  value: string;
  label: string;
  logo: string;
  image: string;
  address: string;
  blackoutDates: DateRange[];
  startPrice: number;
  currency?: string;
  lat: number;
  lng: number;
  roomTypes: RoomType[];
}

// Helper function to check if a date is within a date range
const isDateInRange = (date: Date, range: DateRange): boolean => {
  const dateMonth = date.getMonth();
  const dateDay = date.getDate();
  
  const startMonth = range.start.month;
  const startDay = range.start.date;
  const endMonth = range.end.month;
  const endDay = range.end.date;

  // If the range spans across months
  if (startMonth !== endMonth) {
    // Check if date is after start date in start month
    if (dateMonth === startMonth) {
      return dateDay >= startDay;
    }
    // Check if date is before end date in end month
    if (dateMonth === endMonth) {
      return dateDay <= endDay;
    }
    // Check if date is in between months
    return dateMonth > startMonth && dateMonth < endMonth;
  }

  // If the range is within the same month
  return dateMonth === startMonth && dateDay >= startDay && dateDay <= endDay;
};

// Function to check if a date is blacked out
export const isDateBlackedOut = (date: Date, hotel: Hotel): boolean => {
  return hotel.blackoutDates.some(range => isDateInRange(date, range));
};

// Function to get available room types for a specific date
export const getAvailableRoomTypes = (date: Date, hotel: Hotel): RoomType[] => {
  return hotel.roomTypes.filter(roomType => {
    return roomType.applyDate.some(range => isDateInRange(date, range));
  });
};

// Function to get price for a specific room type on a specific date
export const getRoomPrice = (date: Date, roomType: RoomType): string | null => {
  const applicableRange = roomType.applyDate.find(range => isDateInRange(date, range));
  return applicableRange ? applicableRange.price : null;
};

// Function to get all hotels for a specific country
// export const getHotelsByCountry = (countryCode: string): Hotel[] => {
//   const country = hotelCountries.find(c => c.value === countryCode);
//   return country ? country.hotels : [];
// };

// Function to get all available hotels for a specific date and country
// export const getAvailableHotels = (date: Date, countryCode: string): Hotel[] => {
//   const hotels = getHotelsByCountry(countryCode);
//   return hotels.filter(hotel => !isDateBlackedOut(date, hotel));
// }; 