"use client";

import { ReactElement, useEffect, useRef, useState } from "react";
import {
  Container,
  MainContent,
  Title,
  Header,
  Input,
  ChatInput,
  ChatContent,
  Option,
  SeeResults,
  Wrapper,
  RightSidebar,
  SidebarCloseButton,
  MotionWrapper,
  MotionContainer,
  HeaderRightbar,
  WrapperResults,
} from "./styled";
import AnswerComponent from "../../components/Answer";
import { useAppDispatch } from "../../store";
import {
  createQuestionThunk,
  getDetailHistoryThunk,
} from "../../store/actions/chat.action";
import { ReactComponent as DoubleChevronLeft } from "../../assets/double-chevron-left.svg";
import { ReactComponent as DoubleChevronRight } from "../../assets/double-chevron-right.svg";
import { ReactComponent as SendIcon } from "../../assets/send.svg";
import { ReactComponent as ChevronLeft } from "../../assets/chevron-left.svg";
import { Link, useParams } from "react-router-dom";
import { useNewChat } from "../../context/newChatContext";
import FlightResults from "./FlightResults";
import ChatOptions from "../../components/ChatOptions";

interface ResponseDataHotel {
  departStartDate?: string | null;
  departEndDate?: string | null;
  brands?: string[] | null;
  hotel?: {
    city?: string | null;
    country?: string | null;
    name?: string | null;
  };
  next_message?: string;
  result_message?: string;
  confirm_message?: string;
}

interface IOption {
  id: number;
  text: string;
  type: string;
}

interface FormDataFlight {
  departFrom:
    | {
        code: string;
        name: string;
      }[]
    | null;
  departTo:
    | {
        code: string;
        name: string;
      }[]
    | null;
  departEndDate: string | null;
  departStartDate: string | null;
  cabinClass: string | null;
  totalTicket: number;
}

interface ChatStep {
  isReplying?: boolean;
  content: string;
  type: "user" | "bot";
  options?: IOption[];
  payload?: any;
}

export default function Flight() {
  const params = useParams();
  const chatContentRef = useRef<HTMLDivElement>(null);
  const [chatId, setChatId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [detailChat, setDetailChat] = useState<
    {
      role: string;
      content: {
        type: string;
        text: string;
      }[];
    }[]
  >([]);
  // const { history } = useAppSelector((state) => state.chatReducer);
  const [messages, setMessages] = useState<ChatStep[]>([]);
  const [question, setQuestion] = useState("");
  const [formDataFlight, setFormDataFlight] = useState<FormDataFlight>({
    departFrom: null,
    departTo: null,
    departEndDate: null,
    departStartDate: null,
    cabinClass: null,
    totalTicket: 1,
  });
  const [payload, setPayload] = useState<FormDataFlight>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { isNewChat } = useNewChat();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchChatContent = async () => {
    if (!params?.id) return;

    try {
      const res = await dispatch(
        getDetailHistoryThunk({ id: params?.id })
      ).unwrap();
      setDetailChat(res.fullMsg);
    } catch {
    } finally {
    }
  };

  const updateFormDataFlight = (response: FormDataFlight) => {
    setFormDataFlight((prevFormData: any) => {
      const updatedFormData = { ...prevFormData };

      (Object.keys(response) as Array<keyof FormDataFlight>).forEach((key) => {
        if (!!response[key] && response[key] !== prevFormData[key]) {
          updatedFormData[key] = response[key] as FormDataFlight[typeof key];
        }
      });

      return updatedFormData;
    });
  };

  const processDetailChat = async (
    detailChat: { role: string; content: { text: string }[] }[]
  ): Promise<ChatStep[]> => {
    const processedChat: ChatStep[] = await Promise.all(
      detailChat.map(async (item): Promise<ChatStep> => {
        if (item.role === "user") {
          return {
            type: "user",
            content: item.content[0].text,
            options: [],
            payload: null,
          };
        } else if (item.role === "assistant") {
          let parsedContent: any = {};
          try {
            parsedContent = JSON.parse(item.content[0].text);
          } catch (error) {
            console.error("Error parsing assistant content:", error);
          }

          const question =
            parsedContent.next_message ||
            parsedContent.confirm_message ||
            parsedContent.result_message ||
            item.content[0].text;

          let payload = null as any;

          if (parsedContent.result_message) {
            try {
              payload = {
                departFrom: parsedContent.departFrom || "",
                departTo: parsedContent.departTo || "",
                cabinClass: parsedContent.cabinClass || "Economy",
                departStartDate: parsedContent.departStartDate || "",
                departEndDate: parsedContent.departEndDate || "",
              };
            } catch (error) {
              console.error("Error fetching flight results:", error);
            }
          }

          return {
            type: "bot",
            content: question,
            options: [],
            payload,
          };
        }

        return {
          type: "bot",
          content: "",
          options: [],
          payload: null,
        };
      })
    );
    return processedChat;
  };

  // Handle select option
  const handleSelectOption = async (option: IOption) => {
    if (option.type === "confirm") {
      handleFetchFlightResult(option);
      await dispatch(
        createQuestionThunk({
          question: option.text,
          chat_id: chatId,
          need_bot: false,
          bot_role: "user",
          booking_type: 1,
        })
      ).unwrap();
    }

    if (option.type === "modify") {
      setMessages((prevMsg) => [
        ...prevMsg,
        { content: option.text, type: "user" },
      ]);

      await dispatch(
        createQuestionThunk({
          question: option.text,
          chat_id: chatId,
          need_bot: false,
          bot_role: "user",
          booking_type: 1,
        })
      ).unwrap();

      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          {
            content:
              "Got it! Just tell us the new details, and we’ll update them for you",
            type: "bot",
          },
        ]);
      }, 400);

      await dispatch(
        createQuestionThunk({
          question: JSON.stringify({
            next_message:
              "Got it! Just tell us the new details, and we’ll update them for you",
          }),
          chat_id: chatId,
          need_bot: false,
          bot_role: "assistant",
          booking_type: 1,
        })
      );
    }
  };

  const handleFetchFlightResult = async (option: IOption) => {
    try {
      setMessages((prevMsg) => [
        ...prevMsg,
        { content: option.text, type: "user" },
      ]);
      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          { content: "", isReplying: true, type: "bot" },
        ]);
      }, 600);

      setTimeout(async () => {
        const updatedHistoryMessage = [
          ...messages,
          { content: option.text, type: "user" } as ChatStep,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];

        const payload = {
          departFrom: formDataFlight.departFrom
            ? formDataFlight.departFrom[0].code
            : "",
          departTo: formDataFlight.departTo
            ? formDataFlight.departTo[0].code
            : "",
          cabinClass: formDataFlight.cabinClass || "Economy",
          departStartDate: formDataFlight.departStartDate || "",
          departEndDate: formDataFlight.departEndDate || "",
        };

        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          type: "bot",
          content: "Great! Here are the available options for you",
          payload: payload,
        };
        await dispatch(
          createQuestionThunk({
            question: JSON.stringify({
              ...payload,
              result_message: "Great! Here are the available options for you",
            }),
            chat_id: chatId,
            need_bot: false,
            bot_role: "assistant",
            booking_type: 1,
          })
        ).unwrap();
        setMessages(updatedHistoryMessage);
      }, 1200);
    } catch (e) {}
  };

  // Handle send message
  const handleSendMessage = async (subQuestion?: string) => {
    if (!subQuestion) {
      setQuestion("");
    }

    const questionToSend = subQuestion || question;
    const userMessageObject = {
      isReplying: false,
      content: questionToSend,
      type: "user",
      options: [],
      payload: null,
    } as ChatStep;

    try {
      setMessages((prevMsg) => [...prevMsg, userMessageObject]);
      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          { content: "", isReplying: true, type: "bot" },
        ]);
      }, 600);
      const res = await dispatch(
        createQuestionThunk({
          question: questionToSend,
          need_bot: true,
          bot_role: "user",
          booking_type: 1,
          chat_id: chatId,
        })
      ).unwrap();
      updateFormDataFlight(res);

      if (res.chat_id) {
        setChatId(res.chat_id);
      }

      if (res.next_message) {
        const updatedHistoryMessage = [
          ...messages,
          userMessageObject,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];
        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          content: res.next_message,
          isReplying: false,
          type: "bot",
        };
        setMessages(updatedHistoryMessage);
      }

      if (res.confirm_message) {
        const updatedHistoryMessage = [
          ...messages,
          userMessageObject,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];
        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          content: res.confirm_message,
          isReplying: false,
          type: "bot",
          options: [
            { id: 1, text: "Yes, the details are correct", type: "confirm" },
            { id: 2, text: "No, I want to modify the details", type: "modify" },
          ],
        };
        setMessages(updatedHistoryMessage);
      }
    } catch (e) {
      console.log("Send message error:", e);
    }
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  // Use effect to scroll to bottom when content changes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setChatId(params?.id || "");
    fetchChatContent();
  }, [params]);

  useEffect(() => {
    const updateHistory = async () => {
      const processedChat = await processDetailChat(detailChat);
      if (processedChat.length) {
        setMessages(processedChat);
      } else {
        setMessages([
          {
            type: "bot",
            content: `Welcome aboard! I'm your AI Flight Concierge, ready to take your travel experience to new heights! What can I help you discover about the wonderful world of air travel today?`,
          },
        ]);
      }
    };

    updateHistory();
  }, [detailChat]);

  useEffect(() => {
    if (isNewChat) {
      setMessages([
        {
          type: "bot",
          content: `Welcome aboard! I'm your AI Flight Concierge, ready to take your travel experience to new heights! What can I help you discover about the wonderful world of air travel today?`,
        },
      ]);
      setChatId(null);
    }
  }, [isNewChat]);

  return (
    <MotionWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      isOpen={isSidebarOpen}
    >
      <Container>
        <Header>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Link
              to={"/"}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textDecoration: "none",
                gap: "0.5rem",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                padding: 8,
                borderRadius: 8,
              }}
            >
              <ChevronLeft width={14} />
              <p style={{ color: "#fff", margin: 0 }}>Go back</p>
            </Link>
            <Title>
              {isNewChat
                ? "New Chat"
                : detailChat &&
                  detailChat.length !== 0 &&
                  detailChat[0].content[0].text}
            </Title>
          </div>
          {!isSidebarOpen && payload && (
            <DoubleChevronLeft width={14} onClick={toggleSidebar} />
          )}
        </Header>
        <MainContent>
          <ChatContent ref={chatContentRef}>
            {messages &&
              messages.length !== 0 &&
              messages.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <AnswerComponent
                    type={msg.type}
                    isReplying={msg.isReplying}
                    content={msg.content}
                    options={msg.options}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "0 8px 0 48px",
                      gap: 12,
                    }}
                  >
                    {msg.options &&
                      msg.options.length !== 0 &&
                      msg.options.map((option: IOption) => (
                        <Option
                          onClick={() => handleSelectOption(option)}
                          key={option.id}
                        >
                          {option.text}
                        </Option>
                      ))}
                    {msg.payload && (
                      <SeeResults
                        onClick={() => {
                          setPayload(msg.payload);
                          setIsSidebarOpen(true);
                        }}
                      >
                        See Results
                      </SeeResults>
                    )}
                  </div>
                </div>
              ))}
          </ChatContent>

          <ChatOptions
            topicId={1} // 1 for flights
            messages={messages}
            handleSendMessage={handleSendMessage}
          />

          <ChatInput>
            <Input
              rows={3}
              value={question}
              onChange={(event) => setQuestion(event.target.value)}
              placeholder="Hey Kardo! Whats the current price..."
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey == false) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            <button
              type="submit"
              onClick={() => handleSendMessage()}
              style={{
                background: "#404CFF",
                border: "none",
                color: "#fff",
                borderRadius: "20px",
                cursor: "pointer",
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SendIcon width={20} style={{ paddingLeft: 2 }} />
            </button>
          </ChatInput>
        </MainContent>
      </Container>
      <RightSidebar isOpen={isSidebarOpen}>
        <HeaderRightbar>
          <div style={{ position: "absolute" }}>
            {isSidebarOpen && (
              <DoubleChevronRight width={14} onClick={toggleSidebar} />
            )}
          </div>
          <h3 style={{ margin: 0, textAlign: "center", width: "100%" }}>
            Flights
          </h3>
        </HeaderRightbar>
        {payload && <FlightResults payload={payload} />}
      </RightSidebar>
    </MotionWrapper>
  );
}
