export const getHotelBrandLogo = (brand: string) => {
  switch (brand) {
    case "Hilton":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton.png")}
        />
      );
    case "Hyatt":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt.png")}
        />
      );
    case "IHG":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG.png")}
        />
      );
    case "Marriott":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/marriott.png")}
        />
      );
    case "Hilton Garden Inn":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton_garden_inn.png")}
        />
      );
    case "Canopy":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton_canopy.png")}
        />
      );
    case "Hampton":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton_hampton.png")}
        />
      );
    case "Breathless":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_breathless.png")}
        />
      );
    case "Vivid":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_vivid.png")}
        />
      );
    case "Hyatt Zilara":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_zilara.png")}
        />
      );
    case "Grand Hyatt":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_grand.png")}
        />
      );
    case "Secrets":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_secrets.png")}
        />
      );
    case "Dreams":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_dreams.png")}
        />
      );
    case "Ziva":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hyatt_ziva.png")}
        />
      );
    case "InterContinental Hotels":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_intercontinental.png")}
        />
      );
    case "Holiday Inn":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_holiday_inn_club-vacations.png")}
        />
      );
    case "Crowne Plaza":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_crowne_plaza.png")}
        />
      );
    case "Hotel Indigo":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_indigo.png")}
        />
      );
    case "Holiday Inn Express":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_holiday_inn_express.png")}
        />
      );
    case "Staybridge Suites":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_staybridge.png")}
        />
      );
    case "Iberostar Beachfront Resorts":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/IHG_iberostar_beachfront_resorts.jpg")}
        />
      );
    case "Curio Collection":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton_curio.png")}
        />
      );
    case "DoubleTree":
      return (
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'contain'
          }}
          src={require("../../assets/hotels/hilton_doubletree.png")}
        />
      );

    default:
      return <></>;
  }
};
