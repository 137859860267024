"use client";

import {
  AuthContainer,
  AuthCard,
  Title,
  Subtitle,
  Form,
  InputGroup,
  Label,
  Input,
  Button,
  SocialButton,
  Divider,
  Footer,
  Wrapper,
  SSelect,
} from "./styled";
import * as yup from "yup";
import googleIcon from "../../assets/google.svg";
import { listCountries } from "../../constants/countries";
import { useAppDispatch } from "../../store";
import {
  loginWithGoogleThunk,
  registerUserThunk,
} from "../../store/actions/auth.action";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText } from "@mui/material";

type FormValues = {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  selectedCountry: {
    value: string;
    label: string;
  };
};

const schema = yup.object({
  username: yup
    .string()
    .required("Email Address is required!")
    .email("Email Address is invalid"),
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  password: yup.string().required("Password is required!"),
  selectedCountry: yup
    .object({
      value: yup.string().required("Country is required!"),
      label: yup.string().required("Country is required!"),
    })
    .required("Country is required!"),
});

export default function Signup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      selectedCountry: listCountries[0],
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState, watch, setValue } = form;
  const { errors } = formState;

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        registerUserThunk({
          email: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          country: data.selectedCountry.value,
        })
      ).unwrap();
      navigate("/verify-code", { state: { username: data.username } });
    } catch (e: any) {
      console.log(e);
      message.error(e.message || "Register failed!");
    }
  };

  const handleLoginWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await dispatch(
          loginWithGoogleThunk({
            code: response.code,
          })
        ).unwrap();
      } catch (e: any) {
        message.error(e?.message || "Something went wrong!");
      }
    },
    flow: "auth-code",
  });

  return (
    <AuthContainer>
      <Wrapper>
        <AuthCard>
          <Title>Create an account</Title>
          <Subtitle>Get started with Kardo AI</Subtitle>

          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <InputGroup>
                <Label htmlFor="firstname">First Name</Label>
                <Input
                  id="firstname"
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                />
                <FormHelperText
                  style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
                >
                  {errors.firstName?.message}
                </FormHelperText>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="lastname">Last Name</Label>
                <Input
                  id="lastname"
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                />
                <FormHelperText
                  style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
                >
                  {errors.lastName?.message}
                </FormHelperText>
              </InputGroup>
            </div>

            <InputGroup>
              <Label htmlFor="email">Email address</Label>
              <Input
                id="email"
                type="email"
                placeholder="name@example.com"
                {...register("username")}
              />
              <FormHelperText
                style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
              >
                {errors.username?.message}
              </FormHelperText>
            </InputGroup>

            <InputGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Create a password"
                {...register("password")}
              />
              <FormHelperText
                style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
              >
                {errors.password?.message}
              </FormHelperText>
            </InputGroup>

            {/* <InputGroup>
              <Label htmlFor="dob">Date of Birth</Label>
              <SDatePicker
                value={''}
                format="YYYY-MM-DD"
                placeholder="Select Date of Birth"
                onChange={(date) => {
                  field.onChange(date);
                }}
              />
            </InputGroup> */}

            <InputGroup>
              <Label htmlFor="country">Country</Label>
              <SSelect
                value={watch("selectedCountry")}
                options={listCountries}
                onChange={(value: any) => {
                  setValue(
                    "selectedCountry",
                    listCountries.find((item) => item.value === value) ||
                      listCountries[0]
                  );
                }}
                id="country"
              />
            </InputGroup>

            {/* <InputGroup>
              <Label htmlFor="referral">Referral Code (optional)</Label>
              <Input
                id="referral"
                type="text"
                placeholder="Enter referral code"
              />
            </InputGroup> */}

            <Button type="submit">Create account</Button>

            <Divider>or continue with</Divider>

            <SocialButton onClick={handleLoginWithGoogle} type="button">
              <img src={googleIcon} height={20} alt="Google" />
              Continue with Google
            </SocialButton>
            {/* <SocialButton type="button">
              <img src={appleIcon} height={20} alt="Apple" />
              Continue with Apple
            </SocialButton> */}
          </Form>

          <Footer>
            Already have an account? <Link to="/">Sign in</Link>
          </Footer>
        </AuthCard>
      </Wrapper>
    </AuthContainer>
  );
}
