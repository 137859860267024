// MapComponent.js
import React, { useEffect, useRef, useState } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { HeaderRightbar, RightSidebar, SSelect } from "./styled";
import { hotelCountries } from "../../constants/hotelCountries";
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
} from "@vis.gl/react-google-maps";
import CustomSquareMarker from "./CustomerMarker";
import { ReactComponent as DoubleChevronRight } from "../../assets/double-chevron-right.svg";
import { MotionWrapper } from "../Flight/styled";
import BookingForm from "./BookingForm";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import SubscriptionForm from "./SubscriptionForm";
import { LocationCityOutlined, AttachMoneyOutlined } from "@mui/icons-material";
import { getUserInfoThunk } from "../../store/actions/auth.action";
import { Hotel } from "./types";

const HotelMarker = ({
  hotel,
  onClick,
}: {
  hotel: Hotel;
  onClick: () => void;
}) => {
  return (
    <AdvancedMarker
      position={{ lat: hotel.lat, lng: hotel.lng }}
      onClick={onClick}
    >
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -100%)",
          left: "50%",
          top: "50%",
        }}
        onClick={onClick}
      >
        <CustomSquareMarker logo={hotel.logo} />
      </div>
    </AdvancedMarker>
  );
};

const BookingPage = () => {
  const { me } = useAppSelector((state: RootState) => state.authReducer);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<Hotel | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [hotelInfo, setHotelInfo] = useState<any | null>(null);
  const [bookingFormData, setBookingFormData] = useState<any | null>(null);
  const dispatch = useAppDispatch();

  const fetchUser = async () => {
    try {
      await dispatch(getUserInfoThunk()).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  // Use the first hotel's coordinates as the initial center
  const initialCenter = {
    lat: 38.856614,
    lng: 20.3522219,
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <MotionWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      isOpen={isSidebarOpen}
    >
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
        <Map
          defaultCenter={initialCenter}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          mapId={"95f882a45d3a706"}
          defaultZoom={3}
          minZoom={3}
          restriction={{
            latLngBounds: {
              north: 85,
              south: -85,
              west: -180,
              east: 180,
            },
            strictBounds: true,
          }}
        >
          {/* Render all hotels from all countries */}
          {hotelCountries.map((country) =>
            country.cities.map((city) =>
              city.hotels.map((hotel, index) => (
                <HotelMarker
                  key={`${country.value}-${city.value}-${index}`}
                  hotel={hotel}
                  onClick={() => {
                    setSelectedHotel(hotel);
                    setShowInfoWindow(true);
                    setHotelInfo({
                      country: country,
                      city: city,
                    });
                  }}
                />
              ))
            )
          )}

          {showInfoWindow && selectedHotel && (
            <InfoWindow
              headerContent={
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                >
                  {selectedHotel.label}
                </p>
              }
              position={{
                lat: selectedHotel.lat,
                lng: selectedHotel.lng,
              }}
              pixelOffset={[0, -50]}
              onClose={() => setShowInfoWindow(false)}
              style={{ width: 360, display: "flex", gap: 10 }}
            >
              <img
                src={selectedHotel.image}
                alt="logo"
                style={{
                  width: "120px",
                  height: "80px",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <AttachMoneyOutlined />
                    Starting from
                    <b>
                      {selectedHotel.currency || "USD"}{" "}
                      {selectedHotel.startPrice.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}
                    </b>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <LocationCityOutlined />
                    {selectedHotel.address}
                  </p>
                </div>
                <p
                  style={{
                    margin: 0,
                    cursor: "pointer",
                    color: "#3a6df3",
                    alignSelf: "flex-end",
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    setIsSidebarOpen(true);
                    setBookingFormData({
                      hotel: selectedHotel,
                      country: hotelInfo?.country,
                      city: hotelInfo?.city,
                    });
                  }}
                >
                  Book Now
                </p>
              </div>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
      <RightSidebar isOpen={isSidebarOpen}>
        <HeaderRightbar>
          <div style={{ position: "absolute" }}>
            {isSidebarOpen && (
              <DoubleChevronRight width={14} onClick={toggleSidebar} />
            )}
          </div>
          <h3 style={{ margin: 0, textAlign: "center", width: "100%" }}>
            Booking
          </h3>
        </HeaderRightbar>
        {me?.paymentMode === 4 && bookingFormData && (
          <BookingForm defaultHotel={bookingFormData} />
        )}
        {me?.paymentMode !== 4 && (
          <div style={{ padding: "2rem" }}>
            <SubscriptionForm />
          </div>
        )}
      </RightSidebar>
    </MotionWrapper>
  );
};

export default BookingPage;
