/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import { BookingPayload } from "../models/booking.model";

export const createBooking = async (payload: BookingPayload) => {
  const res = await axios.post("/hotel/booking", payload);
  return res;
};

export const getBookingHistory = async () => {
  const res = await axios.get("/hotel/booking-history");
  return res;
};
