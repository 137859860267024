/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import { IPaymentStatus, ISubscription } from "../models/subscription";

export const createSubscription = async (
  paymentGateway: number,
  paymentMode: number,
) => {
  return axios.post('/payment/subscribe', {
    paymentGateway,
    paymentMode,
  });
};

export const deleteSubscription = async () => {
  return axios.get('/payment/remove-subscription');
};