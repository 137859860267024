export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "pm" : "am";

  hours = hours % 12 || 12;

  return `${hours}:${minutes}${period}`;
};

export const formatDateRange = (start: string, end: string) => {
  const startStr = formatTime(start);
  const endStr = formatTime(end);

  return `${startStr} - ${endStr}`;
};
