"use client";

import {
  AuthContainer,
  AuthCard,
  Title,
  Subtitle,
  Form,
  InputGroup,
  Label,
  Input,
  Button,
  SocialButton,
  Divider,
  Footer,
  Wrapper,
} from "./styled";
import googleIcon from "../../assets/google.svg";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { loginThunk, loginWithGoogleThunk } from "../../store/actions/auth.action";
import { useGoogleLogin } from "@react-oauth/google";
import { message } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText } from "@mui/material";

type FormValues = {
  email: string;
  password: string;
};

const schema = yup.object({
  email: yup
    .string()
    .required("Email Address is required!")
    .email("Email Address is invalid"),
  password: yup.string().required("Password is required!"),
});

export default function Login() {
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, formState, watch, setValue } = form;
  const { errors } = formState;

  const handleLoginWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await dispatch(
          loginWithGoogleThunk({
            code: response.code,
          })
        ).unwrap();
      } catch (e: any) {
        message.error(e?.message || "Something went wrong!");
      }
    },
    flow: 'auth-code',
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await dispatch(
        loginThunk({
          email: data.email,
          password: data.password,
        })
      ).unwrap();
    } catch (e: any) {
      message.error(e?.message || "Something went wrong!");
    }
  };

  return (
    <AuthContainer>
      <Wrapper>
        <AuthCard>
          {/* <Logo>
            <img src="/placeholder.svg?height=32&width=32" alt="Logo" />
            <span>Kardo AI</span>
          </Logo> */}

          <Title>Welcome back</Title>
          <Subtitle>Sign in to continue to Kardo AI</Subtitle>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <Label htmlFor="email">Email address</Label>
              <Input
                id="email"
                type="email"
                placeholder="name@example.com"
                {...register('email')}
              />
              <FormHelperText
                style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
              >
                {errors.email?.message}
              </FormHelperText>
            </InputGroup>

            <InputGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Enter your password"
                {...register('password')}
              />
              <FormHelperText
                style={{ fontFamily: "Outfit", margin: "0", color: "red" }}
              >
                {errors.password?.message}
              </FormHelperText>
            </InputGroup>

            <Button type="submit">Sign in</Button>

            <Divider>or continue with</Divider>

            {/* <GoogleLogin
              onSuccess={(res) => console.log(res)}
              onError={() => console.log("error")}
              logo_alignment="center"
            /> */}

            <SocialButton type="button" onClick={() => handleLoginWithGoogle()}>
              {/* <Github size={20} /> */}
              <img src={googleIcon} height={20} alt="Google" />
              Continue with Google
            </SocialButton>
            {/* <SocialButton type="button">
              <img src={appleIcon} height={20} alt="Apple" />
              Continue with Apple
            </SocialButton> */}
          </Form>

          <Footer>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </Footer>
        </AuthCard>
      </Wrapper>
    </AuthContainer>
  );
}
