import { DatePicker, Select } from "antd";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled.div<{ isOpen: boolean }>`
  height: 100%;
  width: 100%;
  width: ${(props) => (props.isOpen ? "calc(100% - 520px)" : "100%")};
  transition: width 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MotionWrapper = motion(Wrapper);

export const SSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  height: 44.89px !important;

  .ant-select-selector {
    background-color: #333 !important;
    border: 1px solid #404040 !important;
    color: white !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    padding: 0 1rem;
  }

  .ant-select-selection-item {
    color: white !important;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgb(255 255 255 / 25%) !important;
  }
`;

export const RightSidebar = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? "0" : "-520px")};
  width: 520px;
  height: 100vh;
  background-color: transparent;
  transition: right 0.3s ease-in-out;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #110f14;

  h3,
  p {
    color: #fff;
  }
`;

export const HeaderRightbar = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  padding: 0 20px;
  align-items: center;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  box-sizing: border-box;
`;

export const PriceTag = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #fff;
  font-size: 0.875rem;

  .amount {
    font-weight: bold;
  }

  .period {
    color: #666;
  }
`;