import axios from "axios";

export const getCurrentLocation = async () => {
  try {
    const response = await axios.get("https://reallyfreegeoip.org/json/");
    return response.data;
  } catch (error) {
    console.error("Error fetching country:", error);
    return {
      country_name: "",
      latitude: 0,
      longitude: 0,
    };
  }
};
