/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as api from "../../services/auth.service";
import {
  FORGOT_PASSWORD,
  GET_USER_INFO,
  LOGIN,
  LOGIN_GOOGLE,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  UPDATE_USER,
  UPLOAD_AVATAR,
  VERIFY_CODE,
} from "./types";
import { IRegister, IUpdateUserPayload } from "../../models/user.model";

export const loginThunk = createAsyncThunk(
  LOGIN,
  async (payload: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await api.login(payload);
      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const loginWithGoogleThunk = createAsyncThunk(
  LOGIN_GOOGLE,
  async (payload: { code: string }, { rejectWithValue }) => {
    try {
      const response = await api.loginWithGoogle(payload.code);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const registerUserThunk = createAsyncThunk(
  'auth/registerUser',
  async (payload: IRegister, {rejectWithValue}) => {
    try {
      const response = await api.register(payload);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const verifyCodeThunk = createAsyncThunk(
  'auth/verifyCode',
  async (
    payload: {
      email: string;
      verificationCode: string;
      verifyType: number;
    },
    {rejectWithValue},
  ) => {
    try {
      const res = await api.verifyCode(
        payload.email,
        payload.verificationCode,
        payload.verifyType,
      );
      return res.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const resendVerificationCodeThunk = createAsyncThunk(
  'auth/resendVerificationCode',
  async (
    payload: {
      email: string;
    },
    {rejectWithValue},
  ) => {
    try {
      await api.resendVerificationCode(payload.email);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const uploadAvatarThunk = createAsyncThunk(
  UPLOAD_AVATAR,
  async (payload: { data: FormData }, { rejectWithValue }) => {
    try {
      const res = await api.uploadAvatar(payload.data);
      console.log("res", res);
      return res;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const getUserInfoThunk = createAsyncThunk(
  GET_USER_INFO,
  async (payload, { rejectWithValue }) => {
    try {
      const res = await api.getUserInfor();
      return res.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  UPDATE_USER,
  async (payload: IUpdateUserPayload, { rejectWithValue }) => {
    try {
      const res = await api.updateUser(payload);
      return res.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const logout = createAction(LOGOUT, (): any => {
  return {};
});
