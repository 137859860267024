import { Select, Button, Spin } from "antd";
import styled from "styled-components";
import { getBestCardsThunk } from "../../store/actions/journey.action";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { capitalizeFirstLetterEachWord } from "../../core/utils/upperCaseFirstLetter";
import PointIcon from "./icons/point.svg";
import MileIcon from "./icons/miles.svg";
import CashbackIcon from "./icons/cashback.svg";
import USIcon from "./icons/countries/us.svg";
import AEIcon from "./icons/countries/ae.svg";
import IndiaIcon from "./icons/countries/india.svg";
import JapanIcon from "./icons/countries/japan.svg";
import EarthIcon from "./icons/countries/earth.svg";

interface CardDto {
  _id: string;
  defaultPrize: number;
  defaultPoint: number;
  rate: number;
  name: string;
  logoUrl: string;
  countryCode: string;
  urlCardHomePage: string;
  accountId?: string;
  userCardId?: string;
}

const listCountries = [
  { value: "", label: "All", flag: <img src={EarthIcon} width={20} alt="" /> },
  {
    value: "US",
    label: "United States",
    flag: <img src={USIcon} width={20} alt="" />,
  },
  {
    value: "AE",
    label: "United Arab Emirates",
    flag: <img src={AEIcon} width={20} alt="" />,
  },
  {
    value: "JP",
    label: "Japan",
    flag: <img src={JapanIcon} width={20} alt="" />,
  },
  {
    value: "IN",
    label: "India",
    flag: <img src={IndiaIcon} width={20} alt="" />,
  },
];

const listRewardFilter = [
  { label: "Miles & Points", value: "mile, point" },
  { label: "Cashback", value: "cash" },
];

export default function MerchantResults({ payload }: any) {
  const dispatch = useAppDispatch();
  const [isCardsLoading, setIsCardsLoading] = useState(true);
  const [units, setUnits] = useState<any>(listRewardFilter[0]);
  const [countryCard, setCountryCard] = useState<any>(listCountries[0]);
  const [listCards, setListCards] = useState<
    {
      point: number;
      unit: "cash" | "mile" | "point";
      cashback?: number | undefined;
      card: CardDto;
    }[]
  >();

  const fetchBestCards = async (countrCard?: string) => {
    try {
      setIsCardsLoading(true);
      const resBestCards = await dispatch(
        getBestCardsThunk({
          mcc: payload?.mcc,
          units: units.value.split(", "),
          countryCard: countryCard.value,
          countryMerchant: payload?.country,
          name: payload?.merchantName,
        })
      ).unwrap();
      setListCards(resBestCards);
    } catch (error) {
    } finally {
      setIsCardsLoading(false);
    }
  };

  const onChangeCountryCard = (value: any) => {
    setCountryCard(listCountries.find((item) => item.value === value));
  };

  const onChangeRewardFilter = (value: any) => {
    setUnits(listRewardFilter.find((item) => item.value === value));
  };

  useEffect(() => {
    fetchBestCards();
  }, [units, countryCard, payload]);

  return (
    <Container>
      <MerchantInfo>
        <MerchantName>{payload?.merchantName}</MerchantName>
        <MerchantCode>
          MCC {payload?.mcc} -{" "}
          {capitalizeFirstLetterEachWord(payload?.typeOfTransaction || "")}
        </MerchantCode>
      </MerchantInfo>

      <FilterContainer>
        {/* <StyledSelect
          defaultValue="best"
          options={[{ value: "best", label: "Your Best Card" }]}
        /> */}
        <StyledSelect
          value={units}
          options={listRewardFilter}
          onChange={(value) => onChangeRewardFilter(value)}
          optionRender={(option) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                padding: "8px 0",
              }}
            >
              {option.data.label}
            </div>
          )}
        />
        <StyledSelect
          value={countryCard}
          options={listCountries}
          onChange={(value) => onChangeCountryCard(value)}
          optionRender={(option) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                padding: "8px 0",
              }}
            >
              {option.data.flag} {option.data.label}
            </div>
          )}
        />
      </FilterContainer>

      {isCardsLoading && <Spin />}

      {!isCardsLoading && (
        <CardList>
          {listCards &&
            listCards.length > 0 &&
            listCards?.map((item, index) => (
              <CardItem key={index}>
                <CardInfo>
                  <CardImage src={item.card.logoUrl} alt={item.card.name} />
                  <CardDetails>
                    <CardName>{item.card.name}</CardName>
                    {item.unit === "point" && (
                      <WrapperPoints>
                        <div
                          style={{
                            backgroundColor: "#e64d4d",
                            borderRadius: "50%",
                            padding: "6px",
                            display: "flex",
                          }}
                        >
                          <img
                            src={PointIcon}
                            alt=""
                            width={16}
                            height={16}
                            style={{ display: "flex" }}
                          />
                        </div>
                        <Points style={{ color: "#e64d4d" }}>
                          {item.point} Points
                        </Points>
                      </WrapperPoints>
                    )}
                    {item.unit === "mile" && (
                      <WrapperPoints>
                        <div
                          style={{
                            backgroundColor: "#51A1FE",
                            borderRadius: "50%",
                            padding: "6px",
                            display: "flex",
                          }}
                        >
                          <img
                            src={MileIcon}
                            alt=""
                            width={16}
                            height={16}
                            style={{ display: "flex" }}
                          />
                        </div>
                        <Points style={{ color: "#51A1FE" }}>
                          {item.point} Miles
                        </Points>
                      </WrapperPoints>
                    )}
                    {item.unit === "cash" && (
                      <WrapperPoints>
                        <div
                          style={{
                            backgroundColor: "#66BB6A",
                            borderRadius: "50%",
                            padding: "6px",
                            display: "flex",
                          }}
                        >
                          <img
                            src={CashbackIcon}
                            alt=""
                            width={16}
                            height={16}
                            style={{ display: "flex" }}
                          />
                        </div>
                        <Points style={{ color: "#66BB6A" }}>
                          {item.point}% Cashback
                        </Points>
                      </WrapperPoints>
                    )}
                  </CardDetails>
                </CardInfo>
                {/* <PayButton type="primary">Pay Now</PayButton> */}
              </CardItem>
            ))}
        </CardList>
      )}
    </Container>
  );
}

const Container = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  padding: 0 20px 20px;
`;

const MerchantInfo = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
`;

const MerchantName = styled.h2`
  color: #fff;
  margin: 0;
  font-size: 20px;
`;

const MerchantCode = styled.p`
  color: #8b8f95;
  margin: 8px 0 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  padding-right: 8px;
  box-sizing: border-box;

  /* Hide default scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1924; /* Track color */
    border: 1px solid #242424;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1b2554; /* Thumb color */
    border-radius: 10px; /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #243b8d; /* Color on hover */
  }

  /* Optional: Add a custom arrow */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Corner color */
  }
`;

const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const CardImage = styled.img`
  width: 120px;
  height: 75px;
  border-radius: 8px;
  object-fit: contain;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const CardName = styled.h3`
  color: #fff;
  margin: 0;
  font-size: 16px;
`;

const WrapperPoints = styled.div`
  display: flex;
  gap: 8px;
`;

const Points = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
`;

const PayButton = styled(Button)`
  background: #3461ff !important;
  border: none !important;
  color: white !important;
  border-radius: 20px !important;
  height: 40px !important;
  padding: 0 24px !important;

  &:hover {
    background: #4070ff !important;
  }
`;

// const StyledSelect = styled(Select)`
//   min-width: 150px;
//   .ant-select-selector {
//     background: rgba(255, 255, 255, 0.05) !important;
//     border: none !important;
//     color: white !important;
//   }
// `;

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  height: 44.89px !important;

  .ant-select-selector {
    background: rgba(255, 255, 255, 0.05) !important;
    border: none !important;
    color: white !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    padding: 0 1rem;
  }

  .ant-select-selection-item {
    color: white !important;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: white !important;
  }
`;
