"use client";

import {
  Container,
  FeatureCards,
  Card,
  Description,
  Title,
  MainContent,
  MotionWrapper,
  BookingBlock,
  BookingTitle,
  BookingMapPreview,
  WrapperContainer,
  NewTag,
  BookingButton,
  ButtonIcon,
  ButtonText,
} from "./styled";
import { topics } from "../../constants/topics";
import { useTopic } from "../../context/topicContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HotelIcon } from "../../assets/hotel.svg";

export default function App() {
  const { setTopic } = useTopic();
  const navigate = useNavigate();
  return (
    <MotionWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* <Logo src={logo} /> */}
      <WrapperContainer>
        <Container>
          <MainContent>
            <Title>How can I help you today?</Title>
            <Description>
              This chatbot helps users find the best flights, hotels, and
              merchants with personalized recommendations and seamless booking
              options.
            </Description>

            <FeatureCards>
              {topics.map((topic) => (
                <Card
                  key={topic.id}
                  to={topic.route}
                  onClick={() => {
                    localStorage.setItem("topic", topic.id.toString());
                    setTopic(localStorage.getItem("topic") || "");
                  }}
                >
                  <h3>{topic.title}</h3>
                  <div style={{ height: 30 }}>
                    <img src={topic.image} alt="" />
                  </div>
                  <p>{topic.subText}</p>
                </Card>
              ))}
            </FeatureCards>
          </MainContent>
        </Container>
        <Container>
          <BookingBlock>
            <NewTag />
            <BookingTitle>Redefine Luxury Hotels @ Up to 75% Off.</BookingTitle>
            <BookingMapPreview
              onClick={() => navigate("/booking")}
              src="https://media.maptiler.com/img/map_of_usa_5d383c23f7.webp"
              alt=""
            />
            <BookingButton onClick={() => navigate("/booking")}>
              <ButtonIcon>
                <HotelIcon />
              </ButtonIcon>
              <ButtonText>Book Hotels With KardoAI</ButtonText>
            </BookingButton>
          </BookingBlock>
        </Container>
      </WrapperContainer>
    </MotionWrapper>
  );
}
