import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TypeContextProps {
  topic: string;
  setTopic: (text: string) => void;
}

const TopicContext = createContext<TypeContextProps | undefined>(undefined);

export const useTopic = (): TypeContextProps => {
  const context = useContext(TopicContext);
  if (!context) {
    throw new Error('useTopic must be used within a TopicProvider');
  }
  return context;
};

interface TopicProviderProps {
  children: ReactNode;
}

export const TopicProvider: React.FC<TopicProviderProps> = ({ children }) => {
  const [topic, setTopic] = useState<string>('');

  return (
    <TopicContext.Provider value={{ topic, setTopic }}>
      {children}
    </TopicContext.Provider>
  );
};
