const CustomSquareMarker = ({logo}: {logo: string}) => {
  return (
    <div
      style={{
        width: "44px",
        height: "44px",
        backgroundColor: "#ffffff",
        border: "2px solid #0000009c",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      <img src={logo} alt="logo" style={{ width: "44px"}} />
    </div>
  );
};

export default CustomSquareMarker;