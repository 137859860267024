"use client";

import { motion } from "framer-motion";
import styled from "styled-components";
import {
  ProgramData,
  FlightCardProps,
} from "../../containers/Flight/FlightItem";

interface ConvertProgramProps {
  data: ProgramData;
}

interface ConvertProgramsProps extends FlightCardProps {}

const ConvertProgram: React.FC<ConvertProgramProps> = ({ data }) => {
  return (
    <Container>
      <Title>{data.membershipName}</Title>
      <ProgramWrapper>
        <Logo>
          <img src={data.programLogo} alt="Program Logo" />
        </Logo>
        <CardList>
          {data.cards?.map((cardName, index) => (
            <Card key={index}>
              <p>{cardName}</p>
              <span>{data.point?.toLocaleString()} pts</span>
            </Card>
          ))}
        </CardList>
      </ProgramWrapper>
    </Container>
  );
};

export default function ConvertPrograms({ data }: ConvertProgramsProps) {
  const additionalFee = () => {
    if (!data.TotalTaxes) return "";
    return `$${(data.TotalTaxes / 100).toFixed(2)} ${data.TaxesCurrency}`;
  };

  return (data.chaseConvert || data.citiConvert || data.amexConvert) && (
    <Wrapper>
      {data.chaseConvert && <ConvertProgram data={data.chaseConvert} />}
      {data.citiConvert && <ConvertProgram data={data.citiConvert} />}
      {data.amexConvert && <ConvertProgram data={data.amexConvert} />}
      {!!additionalFee() && (
        <Note>
          <strong>Note:</strong>{" "}
          <strong style={{ color: "white" }}>{additionalFee()}</strong> fee is
          required for all reward redemptions.
        </Note>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 14px !important;
  font-weight: 600 !important;
  color: rgba(255, 255, 255, 0.6) !important;
  padding: 2px 0 !important;
`;

const ProgramWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Logo = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    padding: 2px 0;
    margin: 0;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
`;

const Note = styled.p`
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 14px !important;
  font-style: italic !important;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
`;
