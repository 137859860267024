import React, { useState } from "react";
import styled from "styled-components";
import { topics } from "../../constants/topics";

const WrapperInput = styled.div``;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 12px;
  justify-content: center;
`;

const OptionButton = styled.button`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

const OptionTitle = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const OptionDescription = styled.div`
  color: #888;
  font-size: 14px;
`;

const ChatOptions = ({
  topicId,
  messages,
  handleSendMessage,
}: {
  topicId: number;
  messages: any[];
  handleSendMessage: (message: string) => void;
}) => {
  return (
    <WrapperInput>
      {messages.length < 2 && (
        <ScrollContainer>
          {topics
            .find((item) => item.id === topicId)
            ?.options.map((item, index) => (
              <OptionButton
                key={index}
                onClick={() => handleSendMessage(item.userMessage)}
              >
                <OptionTitle>{item.title}</OptionTitle>
                <OptionDescription>{item.description}</OptionDescription>
              </OptionButton>
            ))}
        </ScrollContainer>
      )}
    </WrapperInput>
  );
};

export default ChatOptions;
