/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as api from "../../services/journey.service";
import {
  FORGOT_PASSWORD,
  GET_USER_INFO,
  LOGIN,
  LOGIN_GOOGLE,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  VERIFY_CODE,
} from "./types";

export const getPHSearchIdThunk = createAsyncThunk(
  "journeys/getPHSearchId",
  async (
    payload: {
      flightClass: string;
      originCode: string;
      destinationCode: string;
      passengerCount: number;
      departureDate: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getPHSearchId(
        payload.flightClass,
        payload.originCode,
        payload.destinationCode,
        payload.passengerCount,
        payload.departureDate
      );
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const getPHFlightsThunk = createAsyncThunk(
  "journeys/getPHFlights",
  async (
    payload: {
      flightClass: string;
      searchId: string;
      passengerCount: number;
      count: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getPHFlights(
        payload.flightClass,
        payload.searchId,
        payload.passengerCount,
        payload.count
      );
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const getListAIFlightsThunk = createAsyncThunk(
  "journeys/getListAIFlight",
  async (
    payload: {
      origin_airport: string;
      destination_airport: string;
      cabin: string;
      start_date: string;
      end_date: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getListAIFlights(payload);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const searchAIHotelsThunk = createAsyncThunk(
  "hotels/searchAIHotels",
  async (
    payload: {
      fromDate: string;
      toDate: string;
      brands: string;
      city: string;
      country: string;
      name: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.searchAIHotels(
        payload.fromDate,
        payload.toDate,
        payload.brands,
        payload.name,
        payload.city,
        payload.country
      );
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const getBestCardsThunk = createAsyncThunk(
  "merchants/getBestCards",
  async (
    payload: {
      mcc: number;
      units: string[];
      countryCard: string;
      countryMerchant: string;
      name: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getBestCards(
        payload.mcc,
        payload.units,
        payload.countryCard,
        payload.countryMerchant,
        payload.name
      );
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const getMerchantsNearlyThunk = createAsyncThunk(
  "merchants/getMerchantsNearly",
  async (
    payload: {
      lat: number;
      lng: number;
      country: string;
      merchantName: string;
      types: string[];
      placeId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getMerchantsNearly(
        payload.lat,
        payload.lng,
        payload.country,
        payload.merchantName,
        payload.types,
        payload.placeId
      );
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          return rejectWithValue({
            message:
              "Too many searches at a go, please try again at a later time!",
          });
        } else {
          const errorData = error.response?.data as { message: string };

          if (
            errorData.message ===
              "You need to upgrade to PREMIUM TIER to use this feature" ||
            errorData.message ===
              "Error: Need to upgrade to the Premium Tier to search outside your country."
          ) {
            return rejectWithValue({
              message: "Upgrade to Premium to unlock full benefits",
            });
          } else {
            return rejectWithValue({
              message: "Something went wrong!",
            });
          }
        }
      }
      throw error;
    }
  }
);
