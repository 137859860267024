"use client";

import { useMemo, useState } from "react";
import styled from "styled-components";
import { getHotelBrandLogo } from "../../core/utils/getHotelBrandLogo";

export default function HotelCard({ data }: any) {
  const BrandLogo = useMemo(() => getHotelBrandLogo(data.b), []);

  return (
    <Card>
      <HotelImage src={data.i} alt="" />
      <HotelInfo>
        <HotelName>{data.n}</HotelName>
        <HotelDetail>
          <WrapperBrandLogo>{BrandLogo}</WrapperBrandLogo>
          <Points>{data.pt.toLocaleString()} pts</Points>
        </HotelDetail>
      </HotelInfo>
    </Card>
  );
}

const Card = styled.div`
  background: rgba(45, 45, 50, 0.7);
  border-radius: 12px;
  padding: 16px;
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const HotelImage = styled.img`
  border-radius: 4px;
  width: 120px;
  height: 80px;
  object-fit: cover;
`;

const HotelName = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;

const HotelInfo = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const WrapperBrandLogo = styled.div`
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const Points = styled.p`
  color: rgb(147 197 253);
  margin: 0;
  background-color: rgb(59 130 246 / 10%);
  padding: 6px;
  border-radius: 6px;
  font-weight: 500;
`;

const HotelDetail = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;
