import dayjs, { Dayjs } from "dayjs";

export interface DateRange {
  start: {
    month: number; // 0-11 (January is 0)
    date: number;
  };
  end: {
    month: number; // 0-11 (January is 0)
    date: number;
  };
}

export const isDateDisabled = (
  current: Dayjs | null,
  blackoutDates: DateRange[]
): boolean => {
  if (!current) return false;

  return blackoutDates.some((range) => {
    const startDate = dayjs().month(range.start.month).date(range.start.date);
    const endDate = dayjs().month(range.end.month).date(range.end.date);

    const isInRange =
      current.isAfter(startDate, "day") && current.isBefore(endDate, "day");
    const isRangeBoundary =
      current.isSame(startDate, "day") || current.isSame(endDate, "day");

    return isInRange || isRangeBoundary;
  });
};
