import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Select } from "antd";

export const Wrapper = styled.div<{ isOpen: boolean }>`
  height: 100%;
  width: 100%;
  width: ${(props) => (props.isOpen ? "calc(100% - 520px)" : "100%")};
  transition: width 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MotionWrapper = motion(Wrapper);

export const Container = styled.div`
  display: flex;
  color: #ffffff;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 0 1.5rem;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  width: 90%;
  height: 84vh;
  justify-content: space-between;
  margin: auto;
  gap: 1rem;
`;

export const ChatContent = styled.div`
  /* display: 'flex', flexDirection: 'column', gap: '1rem', flex: 1, overflowY: 'scroll'}} */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  overflow-y: auto;

  /* Hide default scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1924; /* Track color */
    border: 1px solid #242424;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1b2554; /* Thumb color */
    border-radius: 10px; /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #243b8d; /* Color on hover */
  }

  /* Optional: Add a custom arrow */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Corner color */
  }
`;

export const Option = styled.p`
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: fit-content;
  padding: 12px;
  margin: 0;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border: 1px solid #3a6df3;
  }
`;

export const SeeResults = styled.p`
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: fit-content;
  padding: 12px;
  margin: 0;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border: 1px solid #3a6df3;
  }
`;

export const Description = styled.p`
  max-width: 600px;
  text-align: center;
  color: #ccc;
  line-height: 24px;
  font-size: 14px;
`;

export const FeatureCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0 0;
  width: 100%;
  max-width: 650px;
`;

export const Card = styled(Link)`
  background-color: #424242;
  padding: 1rem;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none;

  svg {
    color: #00ff00;
    font-size: 1.5rem;
  }

  h2 {
    font-weight: 500;
  }
`;

export const FilterBar = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
`;

export const FilterButton = styled.button<{ active?: boolean }>`
  padding: 0.5rem 1rem;
  background: ${(props) => (props.active ? "#00ff00" : "transparent")};
  color: ${(props) => (props.active ? "#000" : "#fff")};
  border: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.active ? "#00ff00" : "#333")};
  }
`;

export const PromptInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;

  input {
    width: 100%;
    padding: 1rem 3rem;
    background-color: rgba(51, 51, 51, 0.8);
    border: none;
    border-radius: 30px;
    color: white;
    box-sizing: border-box;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #00ff00;
    }
  }
`;

export const SendButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #00cc00;
  }
`;

export const ChatInput = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;

  position: relative;
`;

export const Input = styled.textarea`
  width: 100%;
  flex: 1;
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  resize: none;
  

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: none;
  }
`;

// Right side

export const RightSidebar = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? "0" : "-520px")};
  width: 520px;
  height: 100vh;
  background-color: transparent;
  transition: right 0.3s ease-in-out;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
`;

export const SidebarCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const HeaderRightbar = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  padding: 0 20px;
  align-items: center;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  box-sizing: border-box;
`;

export const WrapperResults = styled.div`
  padding: 0 20px 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: auto;
  box-sizing: border-box;

  /* Hide default scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1924; /* Track color */
    border: 1px solid #242424;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1b2554; /* Thumb color */
    border-radius: 10px; /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #243b8d; /* Color on hover */
  }

  /* Optional: Add a custom arrow */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Corner color */
  }
`;


export const SSelect = styled(Select)`
  width: 200px;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  height: 45px !important;
  align-self: flex-end;

  .ant-select-selector {
    background-color: rgba(45, 45, 50, 0.7) !important;
    border: 1px solid rgba(45, 45, 50, 0.7) !important;
    color: white !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    height: 45px !important;
  }

  .ant-select-selection-item {
    color: white !important;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgb(255 255 255 / 25%) !important;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
