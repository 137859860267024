import styled from "styled-components";
import { ReactComponent as BotReplied } from "../../assets/replied_robot.svg";
import { ReactComponent as BotReplying } from "../../assets/replying_robot.svg";
import { FC } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useAppDispatch } from "../../store";
import { createQuestionThunk } from "../../store/actions/chat.action";
import defaultAvatar from "../../assets/default_avatar.png";
import BotIcon from "../../assets/replied_bot.png";

interface IAnswerComponent {
  type: "user" | "bot";
  content: string;
  isReplying?: boolean;
  options?: any;
}

interface Option {
  id: number;
  text: string;
  type: string;
}

interface WrapperProps {
  type: "user" | "bot";
}

const AnswerComponent: FC<IAnswerComponent> = ({
  type,
  content,
  isReplying = false,
  options,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Wrapper type={type}>
      {isReplying && (
        <>
          {/* <BotReplying /> */}
          <img style={{ width: 40, height: 40 }} src={BotIcon} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 8px",
              gap: 8,
              flex: 1,
            }}
          >
            <WrapperContent>
              <ThreeDots
                visible={true}
                width="fit-content"
                height="10"
                color="#ffffff"
                radius="9"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </WrapperContent>
          </div>
        </>
      )}
      {!isReplying && (
        <>
          {type === "bot" ? (
            <>
              {/* <BotReplied /> */}
              <img style={{ width: 40, height: 40 }} src={BotIcon} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 8px",
                  gap: 8,
                  flex: 1,
                }}
              >
                {/* <p style={{ margin: 0, fontWeight: 600 }}>{type}</p> */}
                <WrapperContent>
                  <Content>{content}</Content>
                </WrapperContent>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 8px",
                  gap: 8,
                  flex: 1,
                }}
              >
                <WrapperContent>
                  <Content>{content}</Content>
                </WrapperContent>
              </div>
              <img style={{ width: 40, height: 40 }} src={defaultAvatar} />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  width: ${props => props.type === "bot" && "60%"};
  align-self: ${props => props.type === "user" ? "flex-end" : "flex-start"};
  align-items: ${props => props.type === "user" ? "center" : "flex-start"};
  padding-right: 10px;
  box-sizing: border-box;
`;

const WrapperContent = styled.div`
  display: inline-flex;
  padding: 15px;
  border-radius: 16px;
  width: fit-content;
  overflow-wrap: anywhere;
  font-size: 16px;
  font-style: normal;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Content = styled.p`
  margin: 0;
  color: #fff;
  font-size: 16px;
`;

export default AnswerComponent;
