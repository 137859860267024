"use client";

import { ReactElement, useEffect, useRef, useState } from "react";
import {
  Container,
  MainContent,
  Title,
  Header,
  Input,
  ChatInput,
  ChatContent,
  Option,
  SeeResults,
  RightSidebar,
  MotionWrapper,
  HeaderRightbar,
  MerchantResultWrapper,
  Overlay,
  Modal,
  MinimizeButton,
} from "./styled";
import AnswerComponent from "../../components/Answer";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  createQuestionThunk,
  getDetailHistoryThunk,
} from "../../store/actions/chat.action";
import { ReactComponent as DoubleChevronLeft } from "../../assets/double-chevron-left.svg";
import { ReactComponent as DoubleChevronRight } from "../../assets/double-chevron-right.svg";
import { ReactComponent as SendIcon } from "../../assets/send.svg";
import { ReactComponent as ChevronLeft } from "../../assets/chevron-left.svg";
import { Link, useParams } from "react-router-dom";
import { useNewChat } from "../../context/newChatContext";
// import HotelResults from "./HotelResults";
import { getCurrentLocation } from "../../core/utils/getCurrentLocation";
import MerchantResults from "./MerchantResults";
import { getMerchantsNearlyThunk } from "../../store/actions/journey.action";
import { APIProvider, Marker, Map } from "@vis.gl/react-google-maps";
import ChatOptions from "../../components/ChatOptions";

interface ResponseDataHotel {
  departStartDate?: string | null;
  departEndDate?: string | null;
  brands?: string[] | null;
  hotel?: {
    city?: string | null;
    country?: string | null;
    name?: string | null;
  };
  next_message?: string;
  result_message?: string;
  confirm_message?: string;
}

interface IOption {
  id: number;
  text: string;
  type: string;
}

interface FormDataMerchant {
  lat: string | null;
  lng: string | null;
  country: string | null;
  merchantName: string | null;
}

interface ChatStep {
  isReplying?: boolean;
  content: string;
  type: "user" | "bot";
  options?: IOption[];
  payload?: any;
}

export default function Merchant() {
  const params = useParams();
  const chatContentRef = useRef<HTMLDivElement>(null);
  const [chatId, setChatId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [messages, setMessages] = useState<ChatStep[]>([]);
  const [question, setQuestion] = useState("");
  const [detailChat, setDetailChat] = useState<
    {
      role: string;
      content: {
        type: string;
        text: string;
      }[];
    }[]
  >([]);
  const [formDataMerchant, setFormDataMerchant] = useState<FormDataMerchant>({
    lat: null,
    lng: null,
    country: null,
    merchantName: null,
  });
  const [payload, setPayload] = useState<any>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { isNewChat } = useNewChat();

  const [isOpenMapView, setIsOpenMapView] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [merchantLocation, setMerchantLocation] = useState<{
    lat: number;
    lng: number;
  }>();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Fetch Chat Content
  const fetchChatContent = async () => {
    if (!params?.id) return;

    try {
      const res = await dispatch(
        getDetailHistoryThunk({ id: params?.id })
      ).unwrap();
      setDetailChat(res.fullMsg);
    } catch {
    } finally {
    }
  };

  const updateFormDataMerchant = (response: any) => {
    setFormDataMerchant((prevFormData) => {
      const updatedFormData: FormDataMerchant = { ...prevFormData };

      updatedFormData.lat =
        response?.geoData?.geometry?.location?.lat ||
        response?.currentLocation?.latitude;
      updatedFormData.lng =
        response?.geoData?.geometry?.location?.lng ||
        response?.currentLocation?.longitude;
      updatedFormData.country =
        response?.location?.country || response?.currentLocation?.country_name;
      updatedFormData.merchantName = response?.merchantName;

      return updatedFormData;
    });
  };

  const processDetailChat = async (
    detailChat: { role: string; content: { text: string }[] }[]
  ): Promise<ChatStep[]> => {
    const processedChat: ChatStep[] = await Promise.all(
      detailChat.map(async (item): Promise<ChatStep> => {
        if (item.role === "user") {
          return {
            type: "user",
            content: item.content[0].text,
            options: [],
            payload: null,
          };
        } else if (item.role === "assistant") {
          let parsedContent: any = {};
          try {
            parsedContent = JSON.parse(item.content[0].text);
          } catch (error) {
            console.error("Error parsing assistant content:", error);
          }

          const question =
            parsedContent.next_message ||
            parsedContent.confirm_message ||
            parsedContent.result_message ||
            item.content[0].text;

          let payload = null as any;

          if (parsedContent.result_message) {
            try {
              payload = {
                lat: parsedContent.lat || "",
                lng: parsedContent.lng || "",
                country: parsedContent.country || "",
                merchantName: parsedContent.merchantName || "",
              };
            } catch (error) {
              console.error("Error fetching merchat results:", error);
            }
          }

          return {
            type: "bot",
            content: question,
            options: [],
            payload,
          };
        }

        return {
          type: "bot",
          content: "",
          options: [],
          payload: null,
        };
      })
    );
    return processedChat;
  };

  // Handle select option
  const handleSelectOption = async (option: IOption) => {
    if (option.type === "confirm") {
      handleFetchMerchantResult(option);
      await dispatch(
        createQuestionThunk({
          question: option.text,
          chat_id: chatId,
          need_bot: false,
          bot_role: "user",
          booking_type: 3,
        })
      ).unwrap();
    }

    if (option.type === "modify") {
      setMessages((prevMsg) => [
        ...prevMsg,
        { content: option.text, type: "user" },
      ]);

      await dispatch(
        createQuestionThunk({
          question: option.text,
          chat_id: chatId,
          need_bot: false,
          bot_role: "user",
          booking_type: 3,
        })
      ).unwrap();

      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          {
            content:
              "Got it! Just tell us the new details, and we’ll update them for you",
            type: "bot",
          },
        ]);
      }, 400);

      await dispatch(
        createQuestionThunk({
          question: JSON.stringify({
            next_message:
              "Got it! Just tell us the new details, and we’ll update them for you",
          }),
          chat_id: chatId,
          need_bot: false,
          bot_role: "assistant",
          booking_type: 3,
        })
      );
    }
  };

  const handleFetchMerchantResult = async (option: IOption) => {
    try {
      setMessages((prevMsg) => [
        ...prevMsg,
        { content: option.text, type: "user" },
      ]);
      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          { content: "", isReplying: true, type: "bot" },
        ]);
      }, 600);

      setTimeout(async () => {
        const updatedHistoryMessage = [
          ...messages,
          { content: option.text, type: "user" } as ChatStep,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];
        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          type: "bot",
          content: "Great! Here are the available options for you",
          payload: formDataMerchant,
        };
        await dispatch(
          createQuestionThunk({
            question: JSON.stringify({
              ...formDataMerchant,
              result_message: "Great! Here are the available options for you",
            }),
            chat_id: chatId,
            need_bot: false,
            bot_role: "assistant",
            booking_type: 3,
          })
        ).unwrap();
        setMessages(updatedHistoryMessage);
      }, 1200);
    } catch (e) {}
  };

  // Handle send message
  const handleSendMessage = async (subQuestion?: string) => {
    if (!subQuestion) {
      setQuestion("");
    }

    const questionToSend = subQuestion || question;
    const userMessageObject = {
      isReplying: false,
      content: questionToSend,
      type: "user",
      options: [],
      payload: null,
    } as ChatStep;

    try {
      setMessages((prevMsg) => [...prevMsg, userMessageObject]);
      setTimeout(() => {
        setMessages((prevMsg) => [
          ...prevMsg,
          { content: "", isReplying: true, type: "bot" },
        ]);
      }, 600);
      const res = await dispatch(
        createQuestionThunk({
          question: questionToSend,
          need_bot: true,
          bot_role: "user",
          booking_type: 3,
          chat_id: chatId,
        })
      ).unwrap();

      let currentLocation = {};
      if (!res.location) {
        currentLocation = await getCurrentLocation();
      }

      updateFormDataMerchant({ ...res, currentLocation });

      if (res.chat_id) {
        setChatId(res.chat_id);
      }

      if (res.next_message) {
        const updatedHistoryMessage = [
          ...messages,
          userMessageObject,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];
        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          content: res.next_message,
          isReplying: false,
          type: "bot",
        };
        setMessages(updatedHistoryMessage);
      }

      if (res.confirm_message) {
        const updatedHistoryMessage = [
          ...messages,
          userMessageObject,
          { isReplying: true, content: "", type: "bot" } as ChatStep,
        ];
        updatedHistoryMessage[updatedHistoryMessage.length - 1] = {
          content: res.confirm_message,
          isReplying: false,
          type: "bot",
          options: [
            { id: 1, text: "Yes, the details are correct", type: "confirm" },
            { id: 2, text: "No, I want to modify the details", type: "modify" },
          ],
        };
        setMessages(updatedHistoryMessage);
      }
    } catch (e) {
      console.log("Send message error:", e);
    }
  };

  const handleSeeMerchantResult = async (payload: FormDataMerchant) => {
    try {
      const res = await dispatch(
        getMerchantsNearlyThunk({
          lat: Number(payload?.lat)!,
          lng: Number(payload?.lng)!,
          country: payload?.country!,
          merchantName: payload?.merchantName!,
          types: [],
          placeId: "",
        })
      ).unwrap();
      setPayload({
        mcc: res?.data?.data?.mccCode,
        merchantName: res?.data?.data?.merchantName,
        merchantLocation: res?.data?.data?.streetAddress,
        country: res?.data?.data?.countryCode || payload?.country,
        typeOfTransaction: res?.data?.data?.mccCategory,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  };

  const onOpenMapPopup = (location: { lat: number; lng: number }) => {
    setMerchantLocation(location);
    setIsMapVisible(true);
    setIsOpenMapView(true);
  };

  const onCloseMapPopup = () => {
    setIsOpenMapView(false);
    setTimeout(() => {
      setIsMapVisible(false);
      setMerchantLocation(undefined);
    }, 300);
  };

  // Use effect to scroll to bottom when content changes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setChatId(params?.id || "");
    fetchChatContent();
  }, [params]);

  useEffect(() => {
    const updateHistory = async () => {
      const processedChat = await processDetailChat(detailChat);
      if (processedChat.length) {
        setMessages(processedChat);
      } else {
        setMessages([
          {
            type: "bot",
            content: `Hey there! I’m your AI Credit Buddy, here to supercharge your rewards and make your payments as smart as your moves. Let’s level up your credit game together!`,
          },
        ]);
      }
    };

    updateHistory();
  }, [detailChat]);

  useEffect(() => {
    if (isNewChat) {
      setMessages([
        {
          type: "bot",
          content: `Hey there! I’m your AI Credit Buddy, here to supercharge your rewards and make your payments as smart as your moves. Let’s level up your credit game together!`,
        },
      ]);
      setChatId(null);
    }
  }, [isNewChat]);

  return (
    <>
      <MotionWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        isOpen={isSidebarOpen}
      >
        <Container>
          <Header>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Link
                to={"/"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textDecoration: "none",
                  gap: "0.5rem",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                <ChevronLeft width={14} />
                <p style={{ color: "#fff", margin: 0 }}>Go back</p>
              </Link>
              <Title>
                {isNewChat
                  ? "New Chat"
                  : detailChat &&
                    detailChat.length !== 0 &&
                    detailChat[0].content[0].text}
              </Title>
            </div>
            {!isSidebarOpen && payload && (
              <DoubleChevronLeft width={14} onClick={toggleSidebar} />
            )}
          </Header>
          <MainContent>
            <ChatContent ref={chatContentRef}>
              {messages &&
                messages.length !== 0 &&
                messages.map((msg, index) => (
                  <>
                    <AnswerComponent
                      type={msg.type}
                      key={index}
                      isReplying={msg.isReplying}
                      content={msg.content}
                      options={msg.options}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "0 8px 0 48px",
                        gap: 12,
                      }}
                    >
                      {msg.options &&
                        msg.options.length !== 0 &&
                        msg.options.map((option: IOption) => (
                          <Option
                            onClick={() => handleSelectOption(option)}
                            key={option.id}
                          >
                            {option.text}
                          </Option>
                        ))}
                      {msg.payload && (
                        <MerchantResultWrapper>
                          <SeeResults
                            onClick={() => {
                              handleSeeMerchantResult(msg.payload);
                              setIsSidebarOpen(true);
                            }}
                          >
                            See Results
                          </SeeResults>
                          <SeeResults
                            onClick={() =>
                              onOpenMapPopup({
                                lat: msg.payload.lat,
                                lng: msg.payload.lng,
                              })
                            }
                          >
                            Open on Map
                          </SeeResults>
                        </MerchantResultWrapper>
                      )}
                    </div>
                  </>
                ))}
            </ChatContent>

            <ChatOptions
              topicId={3} // 3 for merchant
              messages={messages}
              handleSendMessage={handleSendMessage}
            />

            <ChatInput>
              <Input
                rows={3}
                value={question}
                onChange={(event) => setQuestion(event.target.value)}
                placeholder="Hey Kardo! Whats the current price..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey == false) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              <button
                onClick={() => handleSendMessage()}
                style={{
                  background: "#404CFF",
                  border: "none",
                  color: "#fff",
                  borderRadius: "20px",
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SendIcon width={20} style={{ paddingLeft: 2 }} />
              </button>
            </ChatInput>
          </MainContent>
        </Container>
        <RightSidebar isOpen={isSidebarOpen}>
          <HeaderRightbar>
            <div style={{ position: "absolute" }}>
              {isSidebarOpen && (
                <DoubleChevronRight width={14} onClick={toggleSidebar} />
              )}
            </div>
            <h3
              style={{
                margin: 0,
                textAlign: "center",
                width: "100%",
                color: "white",
              }}
            >
              Card To Use
            </h3>
          </HeaderRightbar>
          {payload && <MerchantResults payload={payload} />}
        </RightSidebar>
      </MotionWrapper>
      {/* Popup Modal */}
      {isMapVisible && (
        <Overlay onClick={onCloseMapPopup} isOpen={isOpenMapView}>
          <Modal onClick={(e) => e.stopPropagation()} isOpen={isOpenMapView}>
            <MinimizeButton onClick={onCloseMapPopup}>↙</MinimizeButton>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
              <Map
                disableDefaultUI={true}
                defaultCenter={merchantLocation}
                defaultZoom={14}
                mapId={"95f882a45d3a706"}
                style={{ height: "80vh", width: "80vw" }}
              >
                <Marker position={merchantLocation} />
              </Map>
            </APIProvider>
          </Modal>
        </Overlay>
      )}
    </>
  );
}
