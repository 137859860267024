"use client"

import type React from "react"
import { useState } from "react"
import styled from "styled-components"
import Search from "./icon"

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(45, 45, 50, 0.7) !important;
  border-radius: 8px;
  padding: 8px 12px;
  flex: 1;
`

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #9ca3af;
  margin-right: 8px;
`

const Input = styled.input`
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #FFF;
  
  &::placeholder {
    color: #FFF;
  }
`

interface ISearchProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const SearchComponent = ({placeholder, value, onChange}: ISearchProps) => {
  return (
    <SearchContainer>
      <SearchIconWrapper>
        <Search size={18} />
      </SearchIconWrapper>
      <Input type="text" placeholder={placeholder || "Search..."} value={value} onChange={(e) => onChange(e.target.value)} />
    </SearchContainer>
  )
}

export default SearchComponent

