export const topics = [
  {
    id: 1,
    title: "Flights",
    isBeta: true,
    isComingSoon: false,
    image: require("../assets/flight.png"),
    route: "/flight",
    delay: 300,
    backgroundColor: "#ff9a63",
    backgroundIconColor: "#ec834b",
    subText: "Book flights on Points\nFind your Best Deal",
    options: [
      {
        id: "flight_search",
        title: "Find Flights",
        description: `I'm your go-to guide for all things flying!`,
        userMessage:
          "Can you assist me in finding flights for my upcoming trip, including options for different airlines?",
      },
      {
        id: "flight_deals",
        title: "Redemption Steals",
        description:
          "Which airlines have the cookies worth writing home about?",
        userMessage:
          "Would you be able to help me find affordable flight options for my next vacation?",
      },
      {
        id: "preferred_airlines",
        title: "Preferred Airlines",
        description: "Want to know where the best seats are for leg room?",
        userMessage:
          "Can you assist me in finding flights with my preferred airline for my upcoming travel dates?",
      },
    ],
  },
  {
    id: 2,
    title: "Hotels",
    isBeta: true,
    isComingSoon: false,
    image: require("../assets/hotel.png"),
    route: "/hotel",
    delay: 350,
    backgroundColor: "#b6f26a",
    backgroundIconColor: "#9ae23d",
    subText: "Book Hotels on Points\nTop Rated Stays",
    options: [
      {
        id: "preferred_hotels",
        title: "Preferred Hotels",
        description: `Consider me your personal hotel whisperer, \nready to make your stay so comfortable \nyou'll forget your own address!`,
        userMessage:
          "Can you help me find hotels with specific amenities or from my preferred hotel chains?",
      },
      {
        id: "hotel_search",
        title: "Find Hotels",
        description: `Whether you're here for business, pleasure \nor escaping your in-laws (we don't judge!). \nI'm here to help!`,
        userMessage:
          "Can you help me find hotels for my upcoming trip, including different accommodation options?",
      },
      {
        id: "hotel_deals",
        title: "Redemption Steals",
        description: `Looking for a room with a view that'll \nmake your Instagram followers jealous?`,
        userMessage:
          "Can you assist me in finding affordable hotel deals for my upcoming vacation?",
      },
    ],
  },
  {
    id: 3,
    title: "Merchants",
    isBeta: true,
    isComingSoon: false,
    image: require("../assets/merchant.png"),
    route: "/merchant",
    delay: 400,
    backgroundColor: "#c9a0ff",
    backgroundIconColor: "#b984f9",
    subText: "Available partners Special offers",
    options: [
      {
        id: 'find_cards',
        title: 'Best cards to use?',
        description: `Club sandwich, Fries, Hummus, Chicken Tikka Masala, Sushi? Shoot!`,
        userMessage:
          'Please help me find the best credit cards to use for my upcoming purchases.',
      },
      {
        id: 'find_shops',
        title: 'MCC Code to maximize your credit card rewards',
        description: `I'm your retail radar pointing you to the best deals!`,
        userMessage:
          'Please help me find the best credit cards to use for my upcoming purchases.',
      },
    ],
  },
//   {
//     id: 4,
//     title: "Loyalty Program",
//     isBeta: false,
//     isComingSoon: true,
//       icon: <LoyaltyIcon width={42} height={42} />,
//     route: "/loyalty-program",
//     delay: 450,
//     backgroundColor: "#94dbfa",
//     backgroundIconColor: "#70ccf5",
//     subText: "Track rewards\nMember status & more",
//     options: [],
//   },
];
