/* eslint-disable max-len */
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_SUBSCRIPTION,
  GET_PAYMENT_STATUS,
  DELETE_SUBSCRIPTION,
} from "./types";
import * as api from "../../services/subscription.service";

export const createSubscriptionThunk = createAsyncThunk(
  CREATE_SUBSCRIPTION,
  async (
    payload: {
      paymentGateway: number;
      paymentMode: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.createSubscription(
        payload.paymentGateway,
        payload.paymentMode
      );

      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);

export const deleteSubscriptionThunk = createAsyncThunk(
  DELETE_SUBSCRIPTION,
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.deleteSubscription();
      return response.data.data;
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        return rejectWithValue(exception.response?.data);
      }
      throw exception;
    }
  }
);
