"use client";

import type React from "react";
import { useState, useRef, useEffect } from "react";
import { Button, message, Typography } from "antd";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import {
  resendVerificationCodeThunk,
  verifyCodeThunk,
} from "../../store/actions/auth.action";
import { Link, useLocation } from "react-router-dom";

const { Title, Text } = Typography;

const VerificationCode = () => {
  const dispatch = useAppDispatch();
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  // const [email, setEmail] = useState("name***@gmail.com"); // This would come from your app state
  const location = useLocation();

  // Initialize refs array
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 6);
    // Focus on first input when component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index: number, value: string) => {
    // Only allow digits
    if (!/^\d*$/.test(value)) return;

    const newCode = [...code];
    newCode[index] = value.slice(0, 1); // Only take the first character
    setCode(newCode);

    // Check if all fields are filled
    setIsComplete(newCode.every((digit) => digit !== ""));

    // Move to next input if current one is filled
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Move to previous input on backspace if current input is empty
    if (
      e.key === "Backspace" &&
      !code[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim();

    // Check if pasted content is a 6-digit number
    if (/^\d{6}$/.test(pastedData)) {
      const newCode = pastedData.split("");
      setCode(newCode);
      setIsComplete(true);

      // Focus on the last input
      if (inputRefs.current[5]) {
        inputRefs.current[5].focus();
      }
    }
  };

  const handleResend = async () => {
    await dispatch(
      resendVerificationCodeThunk({
        email: location.state.username,
      })
    );
  };

  const handleSubmit = async () => {
    try {
      const verificationCode = code.join("");
      await dispatch(
        verifyCodeThunk({
          email: location.state.username,
          verificationCode: verificationCode,
          verifyType: 1,
        })
      ).unwrap();
    } catch (error: any) {
      message.error(error.message || 'Something went wrong when verifying the code');
    }
  };

  return (
    <Wrapper>
      <LightShadow>
        <Container>
          <Header>
            <StyledTitle level={3}>Enter verification code</StyledTitle>
            <StyledText>
              We've sent a 6-digits code to your email. Enter it below to start
              maximizing your rewards!
            </StyledText>
          </Header>

          <CodeInputContainer>
            {code.map((digit, index) => (
              <CodeInput
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                maxLength={1}
                autoComplete="off"
                inputMode="numeric"
              />
            ))}
          </CodeInputContainer>

          <ResendText>
            Did not get a code?{" "}
            <span onClick={handleResend}>Click to resend</span>
          </ResendText>

          <StyledButton
            type="primary"
            disabled={!isComplete}
            onClick={handleSubmit}
          >
            Continue
          </StyledButton>

          <BackLink to="/">Back to Sign in</BackLink>
        </Container>
      </LightShadow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: #000;
`;

const LightShadow = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: radial-gradient(
      circle at top right,
      rgba(0, 149, 255, 0.15),
      transparent 60%
    ),
    radial-gradient(
      circle at bottom left,
      rgba(0, 149, 255, 0.15),
      transparent 60%
    );
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background-color: #242424;
  padding: 2.5rem 3.5rem;
  border-radius: 16px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: white;
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledText = styled(Text)`
  &.ant-typography {
    color: rgba(255, 255, 255, 0.65);
    display: block;
  }
`;

const CodeInputContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  width: 100%;
  justify-content: center;
`;

const CodeInput = styled.input`
  width: 40px;
  height: 48px;
  border: none;
  border-bottom: 2px solid #4169e1;
  background-color: transparent;
  font-size: 24px;
  text-align: center;
  color: white;
  outline: none;

  &:focus {
    border-bottom: 2px solid #5c7cfa;
  }

  &:disabled {
    opacity: 0.5;
    border-bottom: 2px solid #666;
  }
`;

const ResendText = styled(Text)`
  &.ant-typography {
    color: rgba(255, 255, 255, 0.65);
    margin-bottom: 24px;
    font-size: 14px;

    span {
      color: #4169e1;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: #4169e1;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  margin-bottom: 16px;

  &:hover,
  &:focus {
    background-color: #5c7cfa;
  }

  &:disabled {
    background-color: #333;
    color: rgba(255, 255, 255, 0.25);
  }
`;

const BackLink = styled(Link)`
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

export default VerificationCode;
