import React, { createContext, useContext, useState, ReactNode } from "react";

interface TypeContextProps {
  isNewChat: boolean;
  setIsNewChat: (value: boolean) => void;
}

const NewChatContext = createContext<TypeContextProps | undefined>(undefined);

export const useNewChat = (): TypeContextProps => {
  const context = useContext(NewChatContext);
  if (!context) {
    throw new Error("useNewChat must be used within a NewChatProvider");
  }
  return context;
};

interface NewChatProviderProps {
  children: ReactNode;
}

export const NewChatProvider: React.FC<NewChatProviderProps> = ({
  children,
}) => {
  const [isNewChat, setIsNewChat] = useState<boolean>(true);

  return (
    <NewChatContext.Provider value={{ isNewChat, setIsNewChat }}>
      {children}
    </NewChatContext.Provider>
  );
};
