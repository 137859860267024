/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import {
  ILogin,
  IRegister,
  IForgotPassword,
  IUpdateUserPayload,
  ILoginGoogleApple,
} from "../models/user.model";

export const login = async (payload: ILogin) => {
  const res = await axios.post("/user/login", payload);
  return res;
};

export const loginWithGoogle = async (code: string) => {
  const res = await axios.post("/user/login-google-web", { code });
  return res;
};

export const loginWithApple = async (payload: ILoginGoogleApple) => {
  const res = await axios.post("/user/login-apple", payload);
  return res;
};

export const register = async (payload: IRegister) => {
  const res = await axios.post("/user/create", {
    ...payload,
    registerFrom: "web",
  });
  return res;
};

export const forgotPassword = async (payload: IForgotPassword) => {
  const res = await axios.post("/user/reset-password", payload);
  return res;
};

export const getUserInfor = async () => {
  return await axios.get("/user/info");
};

export const updateUser = async (payload: IUpdateUserPayload) => {
  return await axios.put("/user/update", payload);
};

export const deleteUser = async (id: string, feedback: string) => {
  return await axios.post(`/user/delete`, {
    userId: id,
    feedback: feedback,
  });
};

export const updateAppleSubscription = async (subscriptionId: string) => {
  const res = await axios.put("/user/apple-subscription", { subscriptionId });
  return res;
};

export const verifyCode = async (
  email: string,
  verificationCode: string,
  verifyType: number
) => {
  const res = await axios.post("/user/verify-code", {
    email,
    verificationCode,
    verifyType,
  });
  return res;
};

export const resendVerificationCode = async (email: string) => {
  const res = await axios.post("/user/resend-verification-code", {
    email,
  });
  return res;
};

export const uploadAvatar = async (data: FormData) => {
  const res = await axios.post("/user/avatar", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res?.data?.data;
};
