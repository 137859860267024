import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MainLayout from "./containers/MainLayout";
import AuthLayout from "./containers/Authentication/layout";
import AppsComponent from "./containers/Apps";
import { useAppSelector } from "./store";
import Login from "./containers/Authentication/Login";
import Signup from "./containers/Authentication/Signup";
import Hotel from "./containers/Hotel";
import Flight from "./containers/Flight";
import { useTopic } from "./context/topicContext";
import { useNewChat } from "./context/newChatContext";
import Merchant from "./containers/Merchant";
import VerificationCode from "./containers/Authentication/VerifyCode";
import Booking from "./containers/Booking";
import ProfilePage from "./containers/Profile";

export default function App() {
  const authState = useAppSelector((state) => state.authReducer);
  const { setTopic } = useTopic();
  const { setIsNewChat } = useNewChat();
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const hasIdInUrl = pathParts.length > 2 && pathParts[2] !== "";
    const isValidRoute = ["/hotel", "/flight", "/merchant"].some((route) =>
      location.pathname.startsWith(route)
    );

    if (hasIdInUrl && isValidRoute) {
      setIsNewChat(false);
    } else {
      setIsNewChat(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const storedTopic = localStorage.getItem("topic");
    setTopic(storedTopic || "");
  }, [location.pathname]);

  return (
    <Routes>
      {!authState.me && (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-code" element={<VerificationCode />} />
        </>
      )}
      <Route element={<AuthLayout Layout={MainLayout} />}>
        <Route path="/" element={<AppsComponent />} />
        <Route path="/hotel/:id?" element={<Hotel />} />
        <Route path="/flight/:id?" element={<Flight />} />
        <Route path="/merchant/:id?" element={<Merchant />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
