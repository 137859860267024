/* eslint-disable max-len */
import axios, { AxiosResponse } from "axios";
import {
  ILogin,
  IRegister,
  IForgotPassword,
  IUpdateUserPayload,
  ILoginGoogleApple,
} from "../models/user.model";
import { ListAIFlightPayload } from "../models/journey.model";

export const getPHSearchId = async (
  flightClass: string,
  originCode: string,
  destinationCode: string,
  passengerCount: number,
  departureDate: string
): Promise<AxiosResponse> => {
  const res = await axios.get(
    `/journey/search-id?flightClass=${flightClass}&originCode=${originCode}&destinationCode=${destinationCode}&passengerCount=${passengerCount}&departureDate=${departureDate}`
  );
  return res;
};

export const getPHFlights = async (
  flightClass: string,
  searchId: string,
  passengerCount: number,
  count: number
): Promise<AxiosResponse> => {
  const res = await axios.get(
    `/journey/search-flights?flightClass=${flightClass}&searchId=${searchId}&passengerCount=${passengerCount}&count=${count}`
  );
  return res;
};

export const getListAIFlights = async (
  payload: ListAIFlightPayload
): Promise<AxiosResponse> => {
  const res = await axios.get(`/journey/ai-flights`, {
    params: payload,
  });
  return res;
};

export const searchAIHotels = async (
  fromDate: string,
  toDate: string,
  brands: string,
  name: string,
  city: string,
  country: string
): Promise<AxiosResponse["data"]> => {
  const res = await axios.get(
    `/hotel/search/?ci=${fromDate}&co=${toDate}&brands=${brands}&city=${city}&country=${country}&name=${name}`
  );
  return res?.data?.data;
};

export const getBestCards = async (
  mcc: number,
  units: string[],
  countryCard: string,
  countryMerchant: string,
  name: string
) => {
  const res = await axios.get(
    `/shops/${mcc}/best-cards/2/?units=${units}&countryCodeCard=${countryCard}&countryCodeMerchant=${countryMerchant}&name=${name}`
  );
  return res;
};

export const getMerchantsNearly = async (
  lat: number,
  lng: number,
  country: string,
  merchantName: string,
  types: string[],
  placeId: string
) => {
  const res = await axios.post(`/shops/findMerchants/`, {
    place: {
      id: placeId,
      countryCode: country,
      hasNfc: true,
      latitude: lat,
      longitude: lng,
      merchantName: merchantName,
    },
    categories: types,
  });
  return res;
};
