"use client";

import { DatePicker, Select } from "antd";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { message } from "antd";
import { createBookingThunk } from "../../store/actions/booking.action";
import {
  isDateDisabled,
  DateRange as DisabledDateRange,
} from "../../core/utils/disabledDates";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import { hotelCountries } from "../../constants/hotelCountries";
import { Button } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { Dayjs } from "dayjs";
import { getAvailableRoomTypes, getRoomPrice } from "../../utils/hotelUtils";
import { Hotel, RoomType, IPriceRange } from "./types";
import { getUserInfoThunk } from "../../store/actions/auth.action";
const bookingSchema = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    country: yup.string().required("Country is required"),
    city: yup.string().required("City is required"),
    hotel: yup.string().required("Hotel is required"),
    fromDate: yup.date().required("From date is required"),
    toDate: yup.date().required("To date is required"),
    membershipProgram: yup.string().optional(),
    membershipNumber: yup.string().optional(),
    phoneNumber: yup.string().required("Phone number is required"),
    roomType: yup.string().required("Room type is required"),
    hotelRequest: yup.string().optional(),
    kardoAIRequest: yup.string().optional(),
    membershipStatus: yup.string().optional(),
  })
  .test("dates", "To date must be after from date", function (value) {
    if (!value.fromDate || !value.toDate) {
      return true;
    }
    const fromDate = dayjs(value.fromDate).startOf("day");
    const toDate = dayjs(value.toDate).startOf("day");
    const today = dayjs().startOf("day");

    // Check if dates are in the past
    if (fromDate.isBefore(today)) {
      return this.createError({
        message: "From date cannot be in the past",
        path: "fromDate",
      });
    }

    if (toDate.isBefore(today)) {
      return this.createError({
        message: "To date cannot be in the past",
        path: "toDate",
      });
    }

    // Check if toDate is after fromDate (not same or before)
    if (!toDate.isAfter(fromDate)) {
      return this.createError({
        message: "To date must be after from date",
        path: "toDate",
      });
    }
    return true;
  });

interface BookingFormData {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  hotel: string;
  fromDate: Date;
  toDate: Date;
  membershipProgram?: string;
  membershipNumber?: string;
  phoneNumber: string;
  roomType: string;
  hotelRequest?: string;
  kardoAIRequest?: string;
  membershipStatus?: string;
}

interface City {
  value: string;
  label: string;
  hotels: Hotel[];
}

interface HotelCountry {
  value: string;
  label: string;
  cities: City[];
}

interface BookingFormProps {
  defaultHotel?: any;
}

export default function BookingForm({ defaultHotel }: BookingFormProps) {
  console.log(defaultHotel);
  const dispatch = useAppDispatch();
  const [selectedCountry, setSelectedCountry] = useState<HotelCountry>(
    defaultHotel?.country || hotelCountries[0]
  );
  const [selectedCity, setSelectedCity] = useState<City>(
    defaultHotel?.city || hotelCountries[0].cities[0]
  );
  const [selectedHotel, setSelectedHotel] = useState<Hotel>(
    defaultHotel?.hotel || hotelCountries[0].cities[0].hotels[0]
  );
  const [availableRoomTypes, setAvailableRoomTypes] = useState<RoomType[]>([]);
  const [selectedRoomPrice, setSelectedRoomPrice] = useState<string | null>(
    null
  );
  const [showNameNotice, setShowNameNotice] = useState(false);
  const { me } = useAppSelector((state) => state.authReducer);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    trigger,
    reset,
  } = useForm<BookingFormData>({
    resolver: yupResolver(bookingSchema),
    defaultValues: {
      country: hotelCountries[0].value,
      city: hotelCountries[0].cities[0].value,
      hotel: hotelCountries[0].cities[0].hotels[0].value,
      hotelRequest: "",
      kardoAIRequest: "",
      firstName: me?.hotelUserBookingName ? me.firstName : "",
      lastName: me?.hotelUserBookingName ? me.lastName : "",
      email: me?.email || "",
    },
  });

  // Watch for firstName and lastName changes
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useEffect(() => {
    if (firstName && lastName && !errors.firstName && !errors.lastName) {
      setShowNameNotice(true);
    } else {
      setShowNameNotice(false);
    }
  }, [firstName, lastName, errors.firstName, errors.lastName]);

  // Watch for date changes to update available room types
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");

  useEffect(() => {
    if (fromDate && toDate) {
      const availableRooms = getAvailableRoomTypes(fromDate, selectedHotel);
      setAvailableRoomTypes(availableRooms);
      setSelectedRoomPrice(null);
      setValue("roomType", "");

      // Set default room type to first option if available
      if (availableRooms.length > 0) {
        setValue("roomType", availableRooms[0].value);
      }
    }
  }, [fromDate, toDate, selectedHotel]);

  // Watch for room type changes to update price
  const roomType = watch("roomType");
  useEffect(() => {
    if (roomType && fromDate) {
      const selectedRoom = availableRoomTypes.find(
        (room) => room.value === roomType
      );
      if (selectedRoom) {
        const price = getRoomPrice(fromDate, selectedRoom);
        setSelectedRoomPrice(price);
      }
    }
  }, [roomType, fromDate, availableRoomTypes]);

  // Function to check if a price range overlaps with selected dates
  const isRangeOverlapping = (range: IPriceRange) => {
    if (!fromDate || !toDate) return false;

    const rangeStart = dayjs().month(range.start.month).date(range.start.date);
    const rangeEnd = dayjs().month(range.end.month).date(range.end.date);
    const selectedStart = dayjs(fromDate);
    const selectedEnd = dayjs(toDate);

    // Check if the ranges overlap
    return (
      (rangeStart.isBefore(selectedEnd) || rangeStart.isSame(selectedEnd)) &&
      (rangeEnd.isAfter(selectedStart) || rangeEnd.isSame(selectedStart))
    );
  };

  // Function to get applicable price ranges for a room type within selected dates
  const getApplicablePriceRanges = (roomType: string) => {
    const selectedRoom = availableRoomTypes.find(
      (room) => room.value === roomType
    );
    if (!selectedRoom) return [];
    return selectedRoom.applyDate.filter(isRangeOverlapping);
  };

  const onSubmit = async (data: BookingFormData) => {
    try {
      // Validate name values if hotelUserBookingName is true
      if (me?.hotelUserBookingName) {
        if (data.firstName !== me.firstName || data.lastName !== me.lastName) {
          message.error(
            "Name cannot be modified. Please use your registered name."
          );
          return;
        }
      }

      const bookingData = {
        firstName: data.firstName,
        lastName: data.lastName,
        hotelName: data.hotel,
        email: data.email,
        location: data.country,
        city: data.city,
        from: data.fromDate ? dayjs(data.fromDate).format("YYYY-MM-DD") : "",
        to: data.toDate ? dayjs(data.toDate).format("YYYY-MM-DD") : "",
        membershipProgram: data.membershipProgram || "",
        membershipNumber: data.membershipNumber || "",
        phoneNumber: data.phoneNumber,
        roomType: data.roomType,
        price: selectedRoomPrice,
        requestForHotel: data.hotelRequest || "",
        requestForUs: data.kardoAIRequest || "",
        membershipStatus: data.membershipStatus || "",
      };

      await dispatch(createBookingThunk(bookingData)).unwrap();
      message.success("Submission complete. We are working on your booking!");

      // Get updated user info
      const updatedUserInfo = await dispatch(getUserInfoThunk()).unwrap();

      // Reset form values
      reset({
        email: data.email,
        country: data.country,
        city: data.city,
        hotel: data.hotel,
        phoneNumber: "",
        hotelRequest: "",
        kardoAIRequest: "",
        firstName: updatedUserInfo.userData?.hotelUserBookingName
          ? updatedUserInfo.userData.firstName
          : "",
        lastName: updatedUserInfo.userData?.hotelUserBookingName
          ? updatedUserInfo.userData.lastName
          : "",
        fromDate: undefined,
        toDate: undefined,
        roomType: undefined,
        membershipProgram: undefined,
        membershipNumber: "",
        membershipStatus: undefined,
      });
    } catch (e) {
      console.log(e);
      message.error("Booking submission failed!");
    }
  };

  const handleDateChange = async (
    date: unknown,
    field: "fromDate" | "toDate"
  ) => {
    const dayjsDate = date as Dayjs;
    if (dayjs.isDayjs(dayjsDate)) {
      setValue(field, dayjsDate.toDate());

      // If this is fromDate, automatically set toDate to the next day
      if (field === "fromDate") {
        const nextDay = dayjsDate.add(1, "day");
        setValue("toDate", nextDay.toDate());
      }

      // Trigger validation after setting the value
      await trigger(field);
      // If this is fromDate, also trigger toDate validation
      if (field === "fromDate") {
        await trigger("toDate");
      }
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!current) return false;
    return isDateDisabled(current, selectedHotel.blackoutDates);
  };

  useEffect(() => {
    if (defaultHotel) {
      setSelectedCountry(defaultHotel.country);
      setSelectedCity(defaultHotel.city);
      setSelectedHotel(defaultHotel.hotel);
      setValue("country", defaultHotel.country.value);
      setValue("city", defaultHotel.city.value);
      setValue("hotel", defaultHotel.hotel.value);
    }
  }, [defaultHotel]);

  return (
    <AuthContainer>
      <AuthCard>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <InputGroup>
              <Label htmlFor="firstName">
                First Name <span className="required">*</span>
              </Label>
              <Input
                id="firstName"
                type="text"
                placeholder="Enter your first name"
                {...register("firstName")}
                disabled={me?.hotelUserBookingName}
                style={{
                  backgroundColor: me?.hotelUserBookingName
                    ? "#262626"
                    : "#333",
                  cursor: me?.hotelUserBookingName ? "not-allowed" : "text",
                }}
              />
              {errors.firstName && (
                <ErrorMessage>{errors.firstName.message}</ErrorMessage>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="lastName">
                Last Name <span className="required">*</span>
              </Label>
              <Input
                id="lastName"
                type="text"
                placeholder="Enter your last name"
                {...register("lastName")}
                disabled={me?.hotelUserBookingName}
                style={{
                  backgroundColor: me?.hotelUserBookingName
                    ? "#262626"
                    : "#333",
                  cursor: me?.hotelUserBookingName ? "not-allowed" : "text",
                }}
              />
              {errors.lastName && (
                <ErrorMessage>{errors.lastName.message}</ErrorMessage>
              )}
            </InputGroup>
          </div>

          {showNameNotice && !me?.hotelUserBookingName && (
            <NameNotice>
              The name you provide will be permanently associated with your
              account and used for all future hotel bookings. Please ensure it
              matches your official identification documents.
            </NameNotice>
          )}

          <InputGroup>
            <Label htmlFor="email">
              Email <span className="required">*</span>
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="Enter your email"
              {...register("email")}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="phoneNumber">
              Phone Number <span className="required">*</span>
            </Label>
            <Input
              id="phoneNumber"
              type="tel"
              placeholder="Enter phone number"
              {...register("phoneNumber")}
            />
            {errors.phoneNumber && (
              <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>
            )}
          </InputGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <InputGroup>
              <Label htmlFor="country">
                Country <span className="required">*</span>
              </Label>
              <SSelect
                value={selectedCountry.value}
                options={hotelCountries}
                onChange={(value) => {
                  const selectedValue = value as string;
                  const country =
                    hotelCountries.find(
                      (item) => item.value === selectedValue
                    ) || hotelCountries[0];
                  setSelectedCountry(country);
                  setSelectedCity(country.cities[0]);
                  setSelectedHotel(country.cities[0].hotels[0]);
                  setValue("country", selectedValue);
                  setValue("city", country.cities[0].value);
                  setValue("hotel", country.cities[0].hotels[0].value);
                  setValue("membershipProgram", undefined);
                }}
                id="country"
              />
              {errors.country && (
                <ErrorMessage>{errors.country.message}</ErrorMessage>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="city">
                City <span className="required">*</span>
              </Label>
              <SSelect
                value={selectedCity.value}
                options={selectedCountry.cities}
                onChange={(value) => {
                  const selectedValue = value as string;
                  const city =
                    selectedCountry.cities.find(
                      (item) => item.value === selectedValue
                    ) || selectedCountry.cities[0];
                  setSelectedCity(city);
                  setSelectedHotel(city.hotels[0]);
                  setValue("city", selectedValue);
                  setValue("hotel", city.hotels[0].value);
                  setValue("membershipProgram", undefined);
                }}
                id="city"
              />
              {errors.city && (
                <ErrorMessage>{errors.city.message}</ErrorMessage>
              )}
            </InputGroup>
          </div>

          <InputGroup>
            <Label htmlFor="hotel">
              Hotel <span className="required">*</span>
            </Label>
            <SSelect
              value={selectedHotel.value}
              options={selectedCity.hotels.map((hotel) => ({
                value: hotel.value,
                label: hotel.label,
              }))}
              onChange={(value) => {
                const selectedValue = value as string;
                const hotel = selectedCity.hotels.find(
                  (h) => h.value === selectedValue
                );
                if (hotel) {
                  setSelectedHotel(hotel);
                  setValue("hotel", selectedValue);
                  setValue("membershipProgram", undefined);
                }
              }}
              id="hotel"
            />
            {errors.hotel && (
              <ErrorMessage>{errors.hotel.message}</ErrorMessage>
            )}
          </InputGroup>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <InputGroup>
              <Label htmlFor="fromDate">
                From Date <span className="required">*</span>
              </Label>
              <SDatePicker
                value={watch("fromDate") ? dayjs(watch("fromDate")) : null}
                format="YYYY-MM-DD"
                onChange={(date) => handleDateChange(date, "fromDate")}
                disabledDate={disabledDate}
              />
              {errors.fromDate && (
                <ErrorMessage>{errors.fromDate.message}</ErrorMessage>
              )}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="toDate">
                To Date <span className="required">*</span>
              </Label>
              <SDatePicker
                value={watch("toDate") ? dayjs(watch("toDate")) : null}
                format="YYYY-MM-DD"
                onChange={(date) => handleDateChange(date, "toDate")}
                disabledDate={disabledDate}
              />
              {errors.toDate && (
                <ErrorMessage>{errors.toDate.message}</ErrorMessage>
              )}
            </InputGroup>
          </div>

          <InputGroup>
            <Label htmlFor="roomType">
              Room Type <span className="required">*</span>
            </Label>
            <SSelect
              id="roomType"
              value={watch("roomType")}
              onChange={(value: unknown) =>
                setValue("roomType", value as string)
              }
              options={availableRoomTypes.map((room) => ({
                value: room.value,
                label: room.label,
              }))}
              disabled={!fromDate || !toDate}
              placeholder="Select room type"
            />
            {errors.roomType && (
              <ErrorMessage>{errors.roomType.message}</ErrorMessage>
            )}
          </InputGroup>

          {selectedRoomPrice && (
            <PriceDisplay>
              <Label>Price per night:</Label>
              <PriceRanges>
                {getApplicablePriceRanges(roomType).map((range, index) => {
                  const startDate = dayjs()
                    .month(range.start.month)
                    .date(range.start.date);
                  const endDate = dayjs()
                    .month(range.end.month)
                    .date(range.end.date);
                  const dateDisplay = startDate.isSame(endDate, "day")
                    ? startDate.format("MMM D")
                    : `${startDate.format("MMM D")} - ${endDate.format(
                        "MMM D"
                      )}`;

                  return (
                    <PriceRange key={index}>
                      <DateRange>{dateDisplay}:</DateRange>
                      <Price>{range.price}</Price>
                    </PriceRange>
                  );
                })}
              </PriceRanges>
            </PriceDisplay>
          )}

          {/* <InputGroup>
            <Label htmlFor="membershipProgram">Membership Program</Label>
            <SSelect
              id="membershipProgram"
              value={watch("membershipProgram")}
              onChange={(value: unknown) =>
                setValue("membershipProgram", value as string)
              }
              options={[
                { value: "Hilton Honors", label: "Hilton Honors" },
                { value: "Marriott Bonvoy", label: "Marriott Bonvoy" },
                { value: "IHG Rewards", label: "IHG Rewards" },
                { value: "World of Hyatt", label: "World of Hyatt" },
                { value: "Le Accor", label: "Le Accor" },
                { value: "GHA Discovery", label: "GHA Discovery" },
                { value: "Others", label: "Others" },
              ]}
              placeholder="Select membership program"
            />
            {errors.membershipProgram && (
              <ErrorMessage>{errors.membershipProgram.message}</ErrorMessage>
            )}
          </InputGroup> */}
          <InputGroup>
            <Label htmlFor="membershipProgram">Membership Program</Label>
            <SSelect
              id="membershipProgram"
              value={watch("membershipProgram")}
              onChange={(value: unknown) =>
                setValue("membershipProgram", value as string)
              }
              options={selectedHotel.membershipPrograms}
              placeholder="Select membership program"
              allowClear
            />
            {errors.membershipProgram && (
              <ErrorMessage>{errors.membershipProgram.message}</ErrorMessage>
            )}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="membershipNumber">Membership Number</Label>
            <Input
              id="membershipNumber"
              type="text"
              placeholder="Enter membership number"
              {...register("membershipNumber")}
            />
            {errors.membershipNumber && (
              <ErrorMessage>{errors.membershipNumber.message}</ErrorMessage>
            )}
          </InputGroup>

          {selectedHotel.requiredMembership && (
            <InputGroup>
              <Label htmlFor="membershipStatus">Membership Status</Label>
              <SSelect
                id="membershipStatus"
                value={watch("membershipStatus")}
                onChange={(value: unknown) =>
                  setValue("membershipStatus", value as string)
                }
                options={[
                  { value: "Member", label: "Member" },
                  { value: "Silver", label: "Silver" },
                  { value: "Gold", label: "Gold" },
                  { value: "Diamond", label: "Diamond" },
                ]}
                placeholder="Select membership status"
              />
              {errors.membershipStatus && (
                <ErrorMessage>{errors.membershipStatus.message}</ErrorMessage>
              )}
            </InputGroup>
          )}

          <InputGroup>
            <Label htmlFor="hotelRequest">Request For Hotel</Label>
            <TextArea
              id="hotelRequest"
              placeholder="Enter any special requests for the hotel"
              {...register("hotelRequest")}
            />
            {errors.hotelRequest && (
              <ErrorMessage>{errors.hotelRequest.message}</ErrorMessage>
            )}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="kardoAIRequest">Request For KardoAI</Label>
            <TextArea
              id="kardoAIRequest"
              placeholder="Enter any special requests for KardoAI"
              {...register("kardoAIRequest")}
            />
            {errors.kardoAIRequest && (
              <ErrorMessage>{errors.kardoAIRequest.message}</ErrorMessage>
            )}
          </InputGroup>

          <TermsOfService
            href="https://d3cl525s5gdw9u.cloudfront.net/hotel_term.docx"
            download
          >
            Terms Of Service
          </TermsOfService>

          <SButton
            type="submit"
            disabled={isSubmitting}
            onClick={() => trigger()}
          >
            Submit Booking
          </SButton>
        </Form>
      </AuthCard>
    </AuthContainer>
  );
}

const AuthContainer = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  overflow-y: auto;

  /* Hide default scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1924; /* Track color */
    border: 1px solid #242424;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #1b2554; /* Thumb color */
    border-radius: 10px; /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #243b8d; /* Color on hover */
  }

  /* Optional: Add a custom arrow */
  &::-webkit-scrollbar-corner {
    background: transparent; /* Corner color */
  }
`;

const AuthCard = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const Subtitle = styled.p`
  color: #888;
  text-align: center;
  margin-bottom: 2rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  box-sizing: border-box;
  width: 100%;
`;

export const InputGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
`;

export const Label = styled.label`
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 4px;

  .required {
    color: #ff4444;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #333;
  border: 1px solid #404040;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #3a6df3;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
  }

  &::placeholder {
    color: #666;
    font-size: 1rem;
    font-family: inherit;
  }
`;

const SButton = styled(Button)`
  && {
    background: #3461ff;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
    width: 100%;

    &:hover {
      background: #4070ff;
    }

    &.Mui-disabled {
      background: rgba(52, 97, 255, 0.5);
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  color: #666;

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #404040;
  }
`;

export const Footer = styled.p`
  text-align: center;
  color: #666;
  margin-top: 1.5rem;

  a {
    color: #3a6df3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SDatePicker = styled(DatePicker)`
  background-color: #333 !important;
  border: 1px solid #404040;
  color: white !important;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  height: 44.89px !important;

  &:focus {
    outline: none;
    border-color: #3a6df3;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
  }

  &:hover {
    border: 1px solid #404040;
  }

  input::placeholder {
    color: #666 !important;
    font-size: 1rem !important;
    font-family: inherit !important;
  }

  .ant-picker-input > input {
    color: white !important;
    height: 44.89px !important;
    font-size: 1rem !important;
  }

  .ant-picker-suffix {
    color: rgb(255 255 255 / 25%) !important;
  }
`;

export const SSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  height: 44.89px !important;

  .ant-select-selector {
    background-color: #333 !important;
    border: 1px solid #404040 !important;
    color: white !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    padding: 0 1rem;
  }

  .ant-select-selection-item {
    color: white !important;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgb(255 255 255 / 25%) !important;
  }

  .ant-select-selection-placeholder {
    color: #666 !important;
    font-size: 1rem !important;
    font-family: inherit !important;
  }
`;

const ErrorMessage = styled.span`
  color: #ff4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const PriceDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PriceRanges = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PriceRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DateRange = styled.span`
  color: #cecece;
  font-size: 14px;
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #5a89ff;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: #333;
  border: 1px solid #404040;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-family: inherit;
  box-sizing: border-box;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #3a6df3;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2);
  }

  &::placeholder {
    color: #666;
    font-size: 1rem;
    font-family: inherit;
  }
`;

const TermsOfService = styled.a`
  color: #3461ff;
  text-align: center;
  width: 100%;
  text-decoration: none;
`;

const NameNotice = styled.div`
  background-color: rgba(255, 193, 7, 0.1);
  border: 1px solid #ffc107;
  border-radius: 8px;
  padding: 16px;
  color: #ffc107;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  &::before {
    content: "⚠️";
    font-size: 16px;
  }
`;
