/* eslint-disable max-len */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as api from "../../services/chat.service";

export const createQuestionThunk = createAsyncThunk(
  'chat/createQuestion',
  async (
    payload: {
      question: string;
      chat_id: string | null;
      need_bot: boolean;
      bot_role: string;
      booking_type: number;
    },
    {rejectWithValue},
  ) => {
    try {
      const response = await api.createQuestion(
        payload.question,
        payload.chat_id,
        payload.need_bot,
        payload.bot_role,
        payload.booking_type,
      );
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const getHistoryThunk = createAsyncThunk(
  'chat/getHistory',
  async (payload: {bookingType: number; page: number}, {rejectWithValue}) => {
    try {
      const response = await api.getHistory(payload.bookingType, payload.page);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const getDetailHistoryThunk = createAsyncThunk(
  'chat/getDetailHistory',
  async (payload: {id: string}, {rejectWithValue}) => {
    try {
      const response = await api.getDetailHistory(payload.id);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);

export const getAnswerThunk = createAsyncThunk(
  'chat/getAnswer',
  async (payload: {id: string}, {rejectWithValue}) => {
    try {
      const response = await api.getAnswer(payload.id);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      throw error;
    }
  },
);