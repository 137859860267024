export const hotelCountries = [
  {
    value: "FR",
    label: "France",
    cities: [
      {
        value: "Paris",
        label: "Paris",
        hotels: [
          {
            value: "Bulgari Hotel",
            label: "Bulgari Hotel",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/bvlgari.svg",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/bvlgari-photo.jpeg",
            address: "30 Av. George V, 75008 Paris, France",
            blackoutDates: [
              {
                start: { month: 0, date: 21 },
                end: { month: 0, date: 30 },
              },
              {
                start: { month: 2, date: 3 },
                end: { month: 2, date: 11 },
              },
              {
                start: { month: 5, date: 5 },
                end: { month: 5, date: 8 },
              },
              {
                start: { month: 5, date: 16 },
                end: { month: 5, date: 22 },
              },
              {
                start: { month: 5, date: 24 },
                end: { month: 5, date: 29 },
              },
              {
                start: { month: 6, date: 7 },
                end: { month: 6, date: 10 },
              },
              {
                start: { month: 8, date: 29 },
                end: { month: 9, date: 7 },
              },
              {
                start: { month: 11, date: 31 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 1100,
            currency: "EUR",
            lat: 48.856614,
            lng: 2.3522219,
            roomTypes: [
              {
                value: "Executive Room - (45m², available during weekdays)",
                label: "Executive Room - (45m², available during weekdays)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "EUR 1,100",
                  },
                ],
              },
              {
                value: "Executive Room - (45m², available during non-weekdays)",
                label: "Executive Room - (45m², available during non-weekdays)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "EUR 1,300",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Marriott Bonvoy",
                label: "Marriott Bonvoy",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "AE",
    label: "United Arab Emirates",
    cities: [
      {
        value: "Dubai",
        label: "Dubai",
        hotels: [
          {
            value: "SIRO One Za'abeel",
            label: "SIRO One Za'abeel",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/siro.png",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/siro-pt.jpg",
            address: "Za'abeel - Za'abeel 1 - Dubai - United Arab Emirates",
            currency: "AED",
            blackoutDates: [],
            startPrice: 690,
            lat: 24.98546,
            lng: 55.109612,
            roomTypes: [
              {
                value: "SIRO Plus - City View",
                label: "SIRO Plus - City View",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 800",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "AED 800",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 690",
                  },
                ],
              },
              {
                value: "SIRO Premium Double Queen",
                label: "SIRO Premium Double Queen",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 890",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "AED 890",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 790",
                  },
                ],
              },
              {
                value: "SIRO Plus - Burj Khalifa View",
                label: "SIRO Plus - Burj Khalifa View",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,000",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "AED 1,000",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 840",
                  },
                ],
              },
              {
                value: "SIRO Premium King",
                label: "SIRO Premium King",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,350",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "AED 1,350",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,090",
                  },
                ],
              },
              {
                value: "Fitness Suite",
                label: "Fitness Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "On Request",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "On Request",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "On Request",
                  },
                ],
              },
              {
                value: "Recovery Suite",
                label: "Recovery Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "On Request",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "On Request",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "On Request",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Others",
                label: "Others",
              },
            ],
          },
          {
            value: "One&Only One Za'abeel",
            label: "One&Only One Za'abeel",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/one.webp",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/one-pt.webp",
            address: "Za'abeel - Za'abeel 1 - Dubai - United Arab Emirates",
            blackoutDates: [],
            startPrice: 900,
            currency: "AED",
            lat: 25.201728,
            lng: 55.413169,
            roomTypes: [
              {
                value: "Za'abeel Room King - City View",
                label: "Za'abeel Room King - City View",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,000",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,000",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 900",
                  },
                ],
              },
              {
                value: "Za'abeel Room Double Queen",
                label: "Za'abeel Room Double Queen",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,100",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,100",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,000",
                  },
                ],
              },
              {
                value: "Za'abeel Room King - Burj Khalifa View",
                label: "Za'abeel Room King - Burj Khalifa View",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,250",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,250",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,100",
                  },
                ],
              },
              {
                value: "Grand Za'abeel Room King",
                label: "Grand Za'abeel Room King",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,500",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,500",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,200",
                  },
                ],
              },
              {
                value: "Grand Za'abeel Room Double Queen",
                label: "Grand Za'abeel Room Double Queen",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,600",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,600",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,300",
                  },
                ],
              },
              {
                value: "Grand Za'abeel Room King - Burj Khalifa View",
                label: "Grand Za'abeel Room King - Burj Khalifa View",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 1,750",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 1,750",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 1,400",
                  },
                ],
              },
              {
                value: "Za'abeel Suite",
                label: "Za'abeel Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "AED 3,600",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "AED 3,600",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "AED 2,500",
                  },
                ],
              },
              {
                value: "Sanctuary Suite / Creative Suite / Urban Suite",
                label: "Sanctuary Suite / Creative Suite / Urban Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 15 },
                    end: { month: 4, date: 15 },
                    price: "20% off BAR",
                  },
                  {
                    start: { month: 9, date: 1 },
                    end: { month: 11, date: 27 },
                    price: "20% off BAR",
                  },
                  {
                    start: { month: 4, date: 16 },
                    end: { month: 8, date: 30 },
                    price: "20% off BAR",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Others",
                label: "Others",
              },
            ],
          },
        ],
      },
      {
        value: "Abu Dhabi",
        label: "Abu Dhabi",
        hotels: [
          {
            value: "Abu Dhabi Edition",
            label: "Abu Dhabi Edition",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/abu.webp",
            image: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/abu-pt.jpg",
            address:
              "Marina - Al Bateen - W35 - Abu Dhabi - United Arab Emirates",
            blackoutDates: [
              {
                start: { month: 0, date: 9 },
                end: { month: 0, date: 14 },
              },
              {
                start: { month: 1, date: 16 },
                end: { month: 1, date: 20 },
              },
              {
                start: { month: 10, date: 2 },
                end: { month: 10, date: 6 },
              },
              {
                start: { month: 11, date: 4 },
                end: { month: 11, date: 7 },
              },
              {
                start: { month: 11, date: 30 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 650,
            currency: "AED",
            lat: 24.462722,
            lng: 54.60989,
            roomTypes: [
              {
                value: "Deluxe Room",
                label: "Deluxe Room",
                applyDate: [
                  {
                    start: { month: 0, date: 6 },
                    end: { month: 5, date: 15 },
                    price: "AED 765",
                  },
                  {
                    start: { month: 5, date: 16 },
                    end: { month: 8, date: 14 },
                    price: "AED 650",
                  },
                  {
                    start: { month: 8, date: 15 },
                    end: { month: 11, date: 31 },
                    price: "AED 765",
                  },
                ],
              },
              {
                value: "Premium Deluxe Marina Room",
                label: "Premium Deluxe Marina Room",
                applyDate: [
                  {
                    start: { month: 0, date: 6 },
                    end: { month: 5, date: 15 },
                    price: "AED 865",
                  },
                  {
                    start: { month: 5, date: 16 },
                    end: { month: 8, date: 14 },
                    price: "AED 750",
                  },
                  {
                    start: { month: 8, date: 15 },
                    end: { month: 11, date: 31 },
                    price: "AED 865",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Marriott Bonvoy",
                label: "Marriott Bonvoy",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "GB",
    label: "United Kingdom",
    cities: [
      {
        value: "London",
        label: "London",
        hotels: [
          {
            value: "Shangri-La Hotel",
            label: "Shangri-La Hotel",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/shard.webp",
            image: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/shard-pt.webp",
            address: "31 St Thomas St, London SE1 9QU, United Kingdom",
            blackoutDates: [
              {
                start: { month: 1, date: 14 },
                end: { month: 1, date: 15 },
              },
              {
                start: { month: 5, date: 14 },
                end: { month: 5, date: 21 },
              },
              {
                start: { month: 5, date: 30 },
                end: { month: 6, date: 13 },
              },
              {
                start: { month: 11, date: 26 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 321,
            currency: "GBP",
            lat: 51.513219,
            lng: -0.075796,
            roomTypes: [
              {
                value: "Superior Shard Room",
                label: "Superior Shard Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "GBP 321 (Excl. 20% VAT)",
                  },
                ],
              },
              {
                value: "Premium Shard Room",
                label: "Premium Shard Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "GBP 404 (Excl. 20% VAT)",
                  },
                ],
              },
              {
                value: "Deluxe City View Room",
                label: "Deluxe City View Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "GBP 446 (Excl. 20% VAT)",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Shangri La Golden Circle",
                label: "Shangri La Golden Circle",
              },
            ],
          },
          {
            value: "Park Hyatt",
            label: "Park Hyatt",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/park.png",
            image:"https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/park-pt.webp",
            address:
              "7 Nine Elms Ln, Nine Elms, London SW8 5PH, United Kingdom",
            blackoutDates: [],
            startPrice: 330,
            currency: "GBP",
            lat: 51.494792,
            lng: -0.10752,
            roomTypes: [
              {
                value: "Standard Room",
                label: "Standard Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "GBP 330",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "GBP 370",
                  },
                ],
              },
              {
                value: "Deluxe Room",
                label: "Deluxe Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "GBP 370",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "GBP 420",
                  },
                ],
              },
              {
                value: "Park Suite",
                label: "Park Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
              {
                value: "Park Suite River View",
                label: "Park Suite River View",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
              {
                value: "Park Suite Deluxe",
                label: "Park Suite Deluxe",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
              {
                value: "Park Suite River View Deluxe",
                label: "Park Suite River View Deluxe",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
              {
                value: "Ambassador Suite",
                label: "Ambassador Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
              {
                value: "Presidential Suite",
                label: "Presidential Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 2, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                  {
                    start: { month: 3, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "15% off of Standard Rate",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "VN",
    label: "Vietnam",
    cities: [
      {
        value: "Ho Chi Minh City",
        label: "Ho Chi Minh City",
        hotels: [
          {
            value: "Hilton Saigon",
            label: "Hilton Saigon",
            requiredMembership: true,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/hiltonsg.png",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/hiltonsg-pt.avif",
            address: "11 Công trường Mê Linh, Bến Nghé, Quận 1, Hồ Chí Minh",
            blackoutDates: [],
            startPrice: 4000000,
            currency: "VND",
            lat: 10.823096,
            lng: 106.629664,
            roomTypes: [
              {
                value: "Guest Room King/Twin",
                label: "Guest Room King/Twin",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 4,000,000",
                  },
                ],
              },
              {
                value: "Guest City View Room King/Twin",
                label: "Guest City View Room King/Twin",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 4,100,000",
                  },
                ],
              },
              {
                value: "Deluxe River View Room King/Twin",
                label: "Deluxe River View Room King/Twin",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 4,300,000",
                  },
                ],
              },
              {
                value: "Premium Corner River View Room King/Twin",
                label: "Premium Corner River View Room King/Twin",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 5,000,000",
                  },
                ],
              },
              {
                value: "Executive Room King/Twin (Incl. Executive Lounge)",
                label: "Executive Room King/Twin (Incl. Executive Lounge)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 5,500,000",
                  },
                ],
              },
              {
                value:
                  "Deluxe Corner Suite River View King (Incl. Executive Lounge)",
                label:
                  "Deluxe Corner Suite River View King (Incl. Executive Lounge)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 6,700,000",
                  },
                ],
              },
              {
                value:
                  "One Bedroom Suite River View King (Incl. Executive Lounge)",
                label:
                  "One Bedroom Suite River View King (Incl. Executive Lounge)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "VND 11,450,000",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Hilton Honors",
                label: "Hilton Honors",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "SG",
    label: "Singapore",
    cities: [
      {
        value: "Singapore",
        label: "Singapore",
        hotels: [
          {
            value: "Grand Hyatt",
            label: "Grand Hyatt",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/grand.png",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/grand-pt.webp",
            address: "10 Scotts Rd, Singapore 228211",
            blackoutDates: [
              {
                start: { month: 0, date: 1 },
                end: { month: 0, date: 1 },
              },
              {
                start: { month: 9, date: 2 },
                end: { month: 9, date: 5 },
              },
              {
                start: { month: 11, date: 30 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 380,
            currency: "SGD",
            lat: 1.352083,
            lng: 103.819836,
            roomTypes: [
              {
                value: "1 King Bed or 2 Twin Beds (Single Occupancy)",
                label: "1 King Bed or 2 Twin Beds (Single Occupancy)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "SGD 380",
                  },
                ],
              },
              {
                value: "1 King Bed or 2 Twin Beds (Twin/Double Sharing)",
                label: "1 King Bed or 2 Twin Beds (Twin/Double Sharing)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "SGD 380",
                  },
                ],
              },
              {
                value: "1 King Bed with Club Access (Single Occupancy)",
                label: "1 King Bed with Club Access (Single Occupancy)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "SGD 480",
                  },
                ],
              },
              {
                value: "1 King Bed with Club Access (Twin/Double Sharing)",
                label: "1 King Bed with Club Access (Twin/Double Sharing)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "SGD 580",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "KR",
    label: "South Korea",
    cities: [
      {
        value: "Seoul",
        label: "Seoul",
        hotels: [
          {
            value: "Park Hyatt",
            label: "Park Hyatt",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/parkhyatt.png",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/parkhyatt-pt.webp",
            address: "606 Teheran-ro, Gangnam District, Seoul, South Korea",
            blackoutDates: [
              {
                start: { month: 11, date: 24 },
                end: { month: 11, date: 27 },
              },
              {
                start: { month: 11, date: 31 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 380000,
            currency: "KRW",
            lat: 37.5326,
            lng: 126.9783,
            roomTypes: [
              {
                value: "1 King Bed (Room Only, 42 sqm)",
                label: "1 King Bed (Room Only, 42 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 380,000",
                  },
                ],
              },
              {
                value: "1 King Bed (with 1 Breakfast, 42 sqm)",
                label: "1 King Bed (with 1 Breakfast, 42 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 420,000",
                  },
                ],
              },
              {
                value: "1 King Bed / 2 Twin Beds Deluxe (Room Only, 52 sqm)",
                label: "1 King Bed / 2 Twin Beds Deluxe (Room Only, 52 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 450,000",
                  },
                ],
              },
              {
                value:
                  "1 King Bed / 2 Twin Beds Deluxe (with 1 Breakfast, 52 sqm)",
                label:
                  "1 King Bed / 2 Twin Beds Deluxe (with 1 Breakfast, 52 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 490,000",
                  },
                ],
              },
              {
                value: "Park Suite King / Park Suite Twin (Room Only, 64 sqm)",
                label: "Park Suite King / Park Suite Twin (Room Only, 64 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 680,000",
                  },
                ],
              },
              {
                value:
                  "Park Suite King / Park Suite Twin (with 1 Breakfasts, 64 sqm)",
                label:
                  "Park Suite King / Park Suite Twin (with 1 Breakfasts, 64 sqm)",
                applyDate: [
                  {
                    start: { month: 0, date: 14 },
                    end: { month: 11, date: 31 },
                    price: "KRW 720,000",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "US",
    label: "United States",
    cities: [
      {
        value: "San Francisco",
        label: "San Francisco",
        hotels: [
          {
            value: "Ritz Carlton",
            label: "Ritz Carlton",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/ritz.jpg",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/ritz-pt.jpg",
            address: "600 Stockton St, San Francisco, CA 94108, United States",
            blackoutDates: [
              {
                start: { month: 0, date: 12 },
                end: { month: 0, date: 15 },
              },
              {
                start: { month: 0, date: 30 },
                end: { month: 0, date: 31 },
              },
              {
                start: { month: 2, date: 17 },
                end: { month: 2, date: 19 },
              },
              {
                start: { month: 3, date: 27 },
                end: { month: 3, date: 30 },
              },
              {
                start: { month: 4, date: 20 },
                end: { month: 4, date: 21 },
              },
              {
                start: { month: 5, date: 2 },
                end: { month: 5, date: 3 },
              },
              {
                start: { month: 5, date: 9 },
                end: { month: 5, date: 11 },
              },
              {
                start: { month: 8, date: 15 },
                end: { month: 8, date: 17 },
              },
              {
                start: { month: 8, date: 30 },
                end: { month: 9, date: 1 },
              },
              {
                start: { month: 9, date: 13 },
                end: { month: 9, date: 16 },
              },
            ],
            startPrice: 429,
            currency: "USD",
            lat: 37.774929,
            lng: -122.419418,
            roomTypes: [
              {
                value: "Deluxe Guest Room",
                label: "Deluxe Guest Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 429",
                  },
                ],
              },
              {
                value: "City View Guest Room",
                label: "City View Guest Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 499",
                  },
                ],
              },
              {
                value: "Club Level King",
                label: "Club Level King",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 599",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Marriott Bonvoy",
                label: "Marriott Bonvoy",
              },
            ],
          },
        ],
      },
      {
        value: "Boston",
        label: "Boston",
        hotels: [
          {
            value: "Raffles Boston",
            label: "Raffles Boston",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/raff.png",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/raff-pt.webp",
            address: "40 Trinity Pl, Boston, MA 02116, United States",
            blackoutDates: [],
            startPrice: 475,
            currency: "USD",
            lat: 42.358096,
            lng: -71.0636,
            roomTypes: [
              {
                value: "Deluxe Room",
                label: "Deluxe Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 3, date: 13 },
                    price: "USD 475",
                  },
                  {
                    start: { month: 3, date: 14 },
                    end: { month: 5, date: 12 },
                    price: "USD 570",
                  },
                  {
                    start: { month: 5, date: 13 },
                    end: { month: 8, date: 1 },
                    price: "USD 500",
                  },
                  {
                    start: { month: 8, date: 2 },
                    end: { month: 10, date: 20 },
                    price: "USD 590",
                  },
                  {
                    start: { month: 10, date: 21 },
                    end: { month: 11, date: 31 },
                    price: "USD 485",
                  },
                ],
              },
              {
                value: "Premium Room",
                label: "Premium Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 3, date: 13 },
                    price: "USD 525",
                  },
                  {
                    start: { month: 3, date: 14 },
                    end: { month: 5, date: 12 },
                    price: "USD 620",
                  },
                  {
                    start: { month: 5, date: 13 },
                    end: { month: 8, date: 1 },
                    price: "USD 550",
                  },
                  {
                    start: { month: 8, date: 2 },
                    end: { month: 10, date: 20 },
                    price: "USD 640",
                  },
                  {
                    start: { month: 10, date: 21 },
                    end: { month: 11, date: 31 },
                    price: "USD 535",
                  },
                ],
              },
              {
                value: "Emerald Room",
                label: "Emerald Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 3, date: 13 },
                    price: "USD 950",
                  },
                  {
                    start: { month: 3, date: 14 },
                    end: { month: 5, date: 12 },
                    price: "USD 1,150",
                  },
                  {
                    start: { month: 5, date: 13 },
                    end: { month: 8, date: 1 },
                    price: "USD 1,000",
                  },
                  {
                    start: { month: 8, date: 2 },
                    end: { month: 10, date: 20 },
                    price: "USD 1,200",
                  },
                  {
                    start: { month: 10, date: 21 },
                    end: { month: 11, date: 31 },
                    price: "USD 1,000",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Le Accor",
                label: "Le Accor",
              },
            ],
          },
        ],
      },
      {
        value: "Austin",
        label: "Austin",
        hotels: [
          {
            value: "Thomson Austin",
            label: "Thomson Austin",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/thomp.jpg",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/thomp-pt.webp",
            address: "506 San Jacinto Blvd, Austin, TX 78701, United States",
            blackoutDates: [
              {
                start: { month: 2, date: 7 },
                end: { month: 2, date: 12 },
              },
              {
                start: { month: 3, date: 11 },
                end: { month: 3, date: 12 },
              },
              {
                start: { month: 9, date: 3 },
                end: { month: 9, date: 4 },
              },
              {
                start: { month: 9, date: 10 },
                end: { month: 9, date: 11 },
              },
              {
                start: { month: 9, date: 16 },
                end: { month: 9, date: 19 },
              },
            ],
            startPrice: 252,
            currency: "USD",
            lat: 30.167153,
            lng: -97.943057,
            roomTypes: [
              {
                value: "Standard Room",
                label: "Standard Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 252",
                  },
                ],
              },
              {
                value: "Upgraded Room",
                label: "Upgraded Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 312",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
          {
            value: "Tommie Austin",
            label: "Tommie Austin",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/tomm.webp",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/tomm-pt.webp",
            address: "506 San Jacinto Blvd, Austin, TX 78701, United States",
            blackoutDates: [
              {
                start: { month: 2, date: 7 },
                end: { month: 2, date: 12 },
              },
              {
                start: { month: 3, date: 11 },
                end: { month: 3, date: 12 },
              },
              {
                start: { month: 9, date: 3 },
                end: { month: 9, date: 4 },
              },
              {
                start: { month: 9, date: 10 },
                end: { month: 9, date: 11 },
              },
              {
                start: { month: 9, date: 16 },
                end: { month: 9, date: 19 },
              },
            ],
            startPrice: 199,
            currency: "USD",
            lat: 30.467153,
            lng: -97.443057,
            roomTypes: [
              {
                value: "Standard Room",
                label: "Standard Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 199",
                  },
                ],
              },
              {
                value: "Upgraded Room",
                label: "Upgraded Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 239",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
        ],
      },
      {
        value: "Las Vegas",
        label: "Las Vegas",
        hotels: [
          {
            value: "Waldorf Astoria",
            label: "Waldorf Astoria",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/wald.webp",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/wald-pt.webp",
            address:
              "3752 Las Vegas Blvd S, Las Vegas, NV 89158, United States",
            blackoutDates: [
              {
                start: { month: 4, date: 16 },
                end: { month: 4, date: 18 },
              },
              {
                start: { month: 10, date: 19 },
                end: { month: 10, date: 22 },
              },
              {
                start: { month: 10, date: 30 },
                end: { month: 11, date: 5 },
              },
              {
                start: { month: 11, date: 31 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 235,
            currency: "USD",
            lat: 36.1064332,
            lng: -115.1737323,
            roomTypes: [
              {
                value: "City View King Room",
                label: "City View King Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 235",
                  },
                ],
              },
              {
                value: "Strip View King Room",
                label: "Strip View King Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 250",
                  },
                ],
              },
              {
                value: "Waldorf Junior Suite",
                label: "Waldorf Junior Suite",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 335",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Hilton Honors",
                label: "Hilton Honors",
              },
            ],
          },
        ],
      },
      {
        value: "Chicago",
        label: "Chicago",
        hotels: [
          {
            value: "The St. Regis",
            label: "The St. Regis",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/regis.jpg",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/regis-pt.jpeg",
            address: "401 E Wacker Dr, Chicago, IL 60601, United States",
            blackoutDates: [
              {
                start: { month: 4, date: 19 },
                end: { month: 4, date: 20 },
              },
              {
                start: { month: 4, date: 30 },
                end: { month: 5, date: 2 },
              },
              {
                start: { month: 5, date: 9 },
                end: { month: 5, date: 11 },
              },
              {
                start: { month: 6, date: 28 },
                end: { month: 6, date: 29 },
              },
              {
                start: { month: 8, date: 8 },
                end: { month: 8, date: 10 },
              },
              {
                start: { month: 8, date: 29 },
                end: { month: 8, date: 30 },
              },
              {
                start: { month: 9, date: 11 },
                end: { month: 9, date: 11 },
              },
              {
                start: { month: 9, date: 14 },
                end: { month: 9, date: 15 },
              },
              {
                start: { month: 9, date: 27 },
                end: { month: 9, date: 28 },
              },
              {
                start: { month: 10, date: 4 },
                end: { month: 10, date: 5 },
              },
            ],
            startPrice: 465,
            currency: "USD",
            lat: 41.8875572,
            lng: -87.6176403,
            roomTypes: [
              {
                value: "Superior",
                label: "Superior",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "USD 465",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Marriott Bonvoy",
                label: "Marriott Bonvoy",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "HK",
    label: "Hong Kong",
    cities: [
      {
        value: "Hong Kong",
        label: "Hong Kong",
        hotels: [
          {
            value: "Ritz Carlton",
            label: "Ritz Carlton",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/ritzhk.jpg",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/ritzhk-pt.jpeg",
            address:
              "International Commerce Centre (ICC), 九龙柯士甸道西1号, 1 Austin Rd W, Kowloon, Hong Kong",
            blackoutDates: [
              {
                start: { month: 0, date: 1 },
                end: { month: 0, date: 2 },
              },
              {
                start: { month: 0, date: 29 },
                end: { month: 0, date: 31 },
              },
              {
                start: { month: 2, date: 28 },
                end: { month: 2, date: 30 },
              },
              {
                start: { month: 8, date: 29 },
                end: { month: 9, date: 1 },
              },
              {
                start: { month: 10, date: 11 },
                end: { month: 10, date: 13 },
              },
              {
                start: { month: 11, date: 24 },
                end: { month: 11, date: 25 },
              },
              {
                start: { month: 11, date: 30 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 3100,
            currency: "HKD",
            lat: 22.306976,
            lng: 114.16335,
            roomTypes: [
              {
                value: "Deluxe Room",
                label: "Deluxe Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "HKD 3,100",
                  },
                ],
              },
              {
                value: "Deluxe Seaview Room",
                label: "Deluxe Seaview Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "HKD 3,400",
                  },
                ],
              },
              {
                value: "Deluxe Victoria Harbour Room",
                label: "Deluxe Victoria Harbour Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "HKD 4,000",
                  },
                ],
              },
              {
                value: "Grand Seaview Room",
                label: "Grand Seaview Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "HKD 4,200",
                  },
                ],
              },
              {
                value: "Grand Victoria Harbour Room",
                label: "Grand Victoria Harbour Room",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "HKD 4,900",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "Marriott Bonvoy",
                label: "Marriott Bonvoy",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "JP",
    label: "Japan",
    cities: [
      {
        value: "Tokyo",
        label: "Tokyo",
        hotels: [
          {
            value: "Hotel Toranomon Hills",
            label: "Hotel Toranomon Hills",
            requiredMembership: false,
            logo: "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/tora.webp",
            image:
              "https://d3cl525s5gdw9u.cloudfront.net/hotel-booking/tora-pt.webp",
            address:
              "2 Chome-6-4 Toranomon, Minato City, Tokyo 105-0001, Japan",
            blackoutDates: [
              {
                start: { month: 0, date: 1 },
                end: { month: 0, date: 1 },
              },
              {
                start: { month: 2, date: 20 },
                end: { month: 3, date: 6 },
              },
              {
                start: { month: 11, date: 23 },
                end: { month: 11, date: 24 },
              },
              {
                start: { month: 11, date: 30 },
                end: { month: 11, date: 31 },
              },
            ],
            startPrice: 42000,
            currency: "JPY",
            lat: 35.6684416,
            lng: 139.7713887,
            roomTypes: [
              {
                value: "1 King Bed / 2 Twin Beds (27 sqm, Corporate Rate)",
                label: "1 King Bed / 2 Twin Beds (27 sqm, Corporate Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 42,000",
                  },
                ],
              },
              {
                value: "1 King Bed / 2 Twin Beds (32 sqm, Corporate Rate)",
                label: "1 King Bed / 2 Twin Beds (32 sqm, Corporate Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 46,200",
                  },
                ],
              },
              {
                value:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (27 sqm, Corporate Rate)",
                label:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (27 sqm, Corporate Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 44,000",
                  },
                ],
              },
              {
                value:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (32 sqm, Corporate Rate)",
                label:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (32 sqm, Corporate Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 48,400",
                  },
                ],
              },
              {
                value: "1 King Bed / 2 Twin Beds (27 sqm, Standard Rate)",
                label: "1 King Bed / 2 Twin Beds (27 sqm, Standard Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 100,000",
                  },
                ],
              },
              {
                value: "1 King Bed / 2 Twin Beds (32 sqm, Standard Rate)",
                label: "1 King Bed / 2 Twin Beds (32 sqm, Standard Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 110,000",
                  },
                ],
              },
              {
                value:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (27 sqm, Standard Rate)",
                label:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (27 sqm, Standard Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 105,000",
                  },
                ],
              },
              {
                value:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (32 sqm, Standard Rate)",
                label:
                  "1 King Bed with City View / 2 Twin Beds, Deluxe (32 sqm, Standard Rate)",
                applyDate: [
                  {
                    start: { month: 0, date: 1 },
                    end: { month: 11, date: 31 },
                    price: "JPY 115,500",
                  },
                ],
              },
            ],
            membershipPrograms: [
              {
                value: "World of Hyatt",
                label: "World of Hyatt",
              },
            ],
          },
        ],
      },
    ],
  },
];
