import {combineReducers} from 'redux';
import authReducer from './auth.reducer';
import chatReducer from './chat.reducer';
import journeyReducer from './journey.reducer';
import bookingReducer from './booking.reducer';

const rootReducer = combineReducers({
  authReducer,
  chatReducer,
  journeyReducer,
  bookingReducer,
});

export default rootReducer;
