"use client";

import { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
// import { ChevronUp, ChevronDown, Plane } from "lucide-react"
import Plane from "./icons/plane.svg";
import Calendar from "./icons/calendar.svg";
import Clock from "./icons/clock.svg";
import { formatTime } from "../../core/utils/formatDateRange";
import ConvertPrograms from "../../components/ConvertPrograms";

interface FlightSegment {
  FlightNumber: string;
  OriginAirport: string;
  DestinationAirport: string;
  DepartsAt: string;
  ArrivesAt: string;
  originAirportName: string;
  destinationAirportName: string;
  bookUrl: string;
}

interface AirlineFull {
  code: string;
  data: {
    id: string;
    lcc: string;
    name: string;
    logo: string;
  };
}

export interface ProgramData {
  membershipName: string;
  programLogo: string;
  cards?: string[];
  point?: number;
}

export interface FlightCardProps {
  data: {
    Carriers: string;
    AvailabilitySegments: FlightSegment[];
    MileageCost: number;
    TotalTaxes: number;
    TaxesCurrency: string;
    Cabin: string;
    TotalDuration: number;
    Source: string;
    airlineFull: AirlineFull[];
    SourceFlight: string;
    chaseConvert: ProgramData;
    citiConvert: ProgramData;
    amexConvert: ProgramData;
  };
}

const ShortMonthNames = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export default function FlightCard({ data }: FlightCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const getFlightCarriers = () => {
    if (!data?.airlineFull?.length) {
      return data.Carriers;
    }

    return data?.Carriers?.split(", ")
      ?.map((a) => {
        const matchedAirline = data?.airlineFull.find(
          (airline) => airline.code === a
        );

        if (!matchedAirline) {
          return a;
        }

        return matchedAirline?.data?.name || a;
      })
      ?.join(", ");
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.getUTCMonth() + 1; // Months are 0-indexed
    const day = date.getUTCDate();

    return `${ShortMonthNames[month as 1]} ${day}`;

    // const date = new Date(dateString);
    // return date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
  };

  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hr${hours !== 1 ? "s" : ""} ${mins} min${
      mins !== 1 ? "s" : ""
    }`;
  };

  return (
    <Card
      initial={false}
      animate={{ height: isExpanded ? "auto" : "auto" }}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Header>
        <AirlineInfo>
          <AirlineLogo>
            <img src={Plane} width={20} alt="" />
          </AirlineLogo>
          <AirlineDetails>
            <AirlineLogos>
              {data?.airlineFull?.map((airline, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img
                    src={airline.data.logo}
                    width={80}
                    height={24}
                    alt={airline.data.name}
                    style={{
                      objectFit: "contain",
                      background: "rgb(255, 255, 255)",
                      padding: "4px",
                      borderRadius: "8px",
                    }}
                  />
                  {index < data.airlineFull.length - 1 && " → "}
                </div>
              ))}
            </AirlineLogos>
            <FlightNumbers>
              {data.AvailabilitySegments.map((segment, index) => (
                <span key={index}>
                  {segment.FlightNumber}
                  {index < data.AvailabilitySegments.length - 1 && " → "}
                </span>
              ))}
            </FlightNumbers>
            <p>{data.Cabin.toUpperCase()}</p>
          </AirlineDetails>
        </AirlineInfo>
        {/* {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />} */}
      </Header>

      <div
        style={{
          display: "flex",
          gap: "24px",
          marginTop: "12px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <img src={Calendar} width={18} alt="" />
          <span>{formatDate(data.AvailabilitySegments[0].DepartsAt)}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <img src={Clock} width={18} alt="" />
          <span>{formatDuration(data.TotalDuration)}</span>
        </div>
        <span>{data.AvailabilitySegments.length - 1} stop(s)</span>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div
              style={{
                marginTop: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {data.AvailabilitySegments.map((segment, index) => (
                <div key={index}>
                  <FlightInfo>
                    <TimelineContainer>
                      <div
                        style={{
                          width: "8px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Dot color="#22c55e" />
                      </div>
                      <Timeline />
                    </TimelineContainer>
                    <div>
                      <FlightDetails>
                        <h4>{formatTime(segment.DepartsAt)}</h4>
                        <p>
                          {segment.originAirportName || segment.OriginAirport} (
                          {segment.OriginAirport})
                        </p>
                        <p>{segment.FlightNumber}</p>
                      </FlightDetails>
                    </div>
                  </FlightInfo>
                  {index < data.AvailabilitySegments.length - 1 && (
                    <FlightInfo>
                      <TimelineContainer>
                        <div
                          style={{
                            width: "8px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Dot color="#3b82f6" />
                        </div>
                      </TimelineContainer>
                      <div>
                        <FlightDetails>
                          <h4>{formatTime(segment.ArrivesAt)}</h4>
                          <p>
                            {segment.destinationAirportName ||
                              segment.DestinationAirport}{" "}
                            ({segment.DestinationAirport})
                          </p>
                        </FlightDetails>
                      </div>
                    </FlightInfo>
                  )}
                </div>
              ))}
            </div>
            <FlightInfo style={{ marginBottom: "12px" }}>
              <TimelineContainer>
                <div
                  style={{
                    width: "8px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Dot color="#EF4444" />
                </div>
              </TimelineContainer>
              <div>
                <FlightDetails>
                  <h4>
                    {formatTime(
                      data.AvailabilitySegments[
                        data.AvailabilitySegments.length - 1
                      ].ArrivesAt
                    )}
                  </h4>
                  <p>
                    {data.AvailabilitySegments[
                      data.AvailabilitySegments.length - 1
                    ].destinationAirportName ||
                      data.AvailabilitySegments[
                        data.AvailabilitySegments.length - 1
                      ].DestinationAirport}{" "}
                    (
                    {
                      data.AvailabilitySegments[
                        data.AvailabilitySegments.length - 1
                      ].DestinationAirport
                    }
                    )
                  </p>
                </FlightDetails>
              </div>
            </FlightInfo>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        style={{
          margin: "12px 0",
          gap: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PriceInfo>
          <p>Redeem Miles + Taxes</p>
          <span>
            {data.MileageCost.toLocaleString()} pts + $
            {(data.TotalTaxes / 100).toFixed(2)} {data.TaxesCurrency}
          </span>
        </PriceInfo>

        <PriceInfo>
          <p>Redemption Partner</p>
          <span>
            {data.Source.charAt(0).toUpperCase() + data.Source.slice(1)}
          </span>
        </PriceInfo>

        <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ConvertPrograms data={data} />
          </motion.div>
        )}
      </AnimatePresence>
      </div>

      {data?.AvailabilitySegments[0]?.bookUrl && (
        <BookButton
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          href={data?.AvailabilitySegments[0]?.bookUrl}
        >
          Book Flight
        </BookButton>
      )}
    </Card>
  );
}

const Card = styled(motion.div)`
  background: rgba(45, 45, 50, 0.7);
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AirlineInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AirlineLogos = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AirlineLogo = styled.div`
  color: #4b96ff;
`;

const FlightNumbers = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
`;

const AirlineDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }
`;

const FlightInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Timeline = styled.div`
  width: 2px;
  height: 40px;
  background: #4b96ff;
`;

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const FlightDetails = styled.div`
  gap: 2px;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    color: #fff;
  }

  p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }
`;

const BookButton = styled.a`
  width: 100%;
  padding: 12px;
  background: transparent;
  border: 1px solid #4b96ff;
  color: #4b96ff;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    background: #4b96ff;
    color: #fff;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }

  span {
    font-weight: 600;
    color: #fff;
  }
`;
