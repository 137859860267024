import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store";
import {
  getListAIFlightsThunk,
  getPHFlightsThunk,
  getPHSearchIdThunk,
} from "../../store/actions/journey.action";
import { formatDateRange } from "../../core/utils/formatDateRange";
import { WrapperResults, SSelect, FilterWrapper } from "./styled";
import FlightCard from "./FlightItem";
import { Spin } from "antd";
import SearchComponent from "../../components/SearchInput";

type SortOption = {
  value: string;
  label: string;
};

const FlightClassMapping = {
  economy: "Economy",
  premium: "Premium Economy",
  business: "Business & First Class",
  first: "First",
};

const SortOptions = [
  { value: "random", label: "Sort By", invisible: true },
  { value: "lowest-points", label: "Lowest points" },
  { value: "stops", label: "Sort by number of stops" },
  { value: "duration", label: "Sort by duration" },
];

const convertPHFlightToSAFlight = (flight: any) => {
  if (!flight?.offerFlightSegments?.length) {
    return;
  }

  return {
    ...flight,
    SourceFlight: "PH",
    Carriers: flight.airlinesList,
    Cabin: flight.cabinClass,
    TotalDuration: flight.totalDuration,
    MileageCost: flight.bestPricePoints,
    TotalTaxes: flight.pricePointsAdditional * 100,
    TaxesCurrency: flight.pricePointsAdditionalCurrency,
    Source: flight.sourceIdentifier,
    airlineFull: flight.offerFlightSegments.map((segment: any) => ({
      code: segment.airline?.iataCode,
      data: {
        id: segment.airline?.iataCode,
        name: segment.airline?.name,
        logo: segment.airline?.logoUrl,
      },
    })),
    AvailabilitySegments: flight.offerFlightSegments.map((segment: any) => ({
      FlightNumber: segment.flightNumber,
      OriginAirport: segment.originCode,
      DestinationAirport: segment.destinationCode,
      DepartsAt: segment.departsAt,
      ArrivesAt: segment.arrivesAt,
      originAirportName: segment.originAirport?.name || "",
      destinationAirportName: segment.destinationAirport?.name || "",
      bookUrl: flight.source?.bookUrl,
    })),
  };
};

const generateFlightKey = (flight: any) => {
  if (!flight || !flight.AvailabilitySegments?.length) return;

  let key = `${flight.MileageCost}-${flight.TotalDuration}-${flight.Cabin}`;

  flight.AvailabilitySegments.forEach((segment: any) => {
    key += `-${segment?.FlightNumber}`;
    key += `-${new Date(segment?.DepartsAt).toLocaleDateString()}`;
    key += `-${new Date(segment?.ArrivesAt).toLocaleDateString()}`;
    key += `-${formatDateRange(segment?.DepartsAt, segment?.ArrivesAt)}`;
    key += `-${segment?.OriginAirport}`;
    key += `-${segment?.DestinationAirport}`;
  });

  return key;
};

const getUniqueAirlineNames = (
  data: {id: number; airline: {iataCode: string; name: string}}[],
) => {
  const uniqueAirlinesMap = new Map<string, string>();

  data.forEach(item => {
    const {iataCode, name} = item.airline;
    if (!uniqueAirlinesMap.has(iataCode)) {
      uniqueAirlinesMap.set(iataCode, name);
    }
  });

  return Array.from(uniqueAirlinesMap.values());
};

const FlightResults = ({ payload }: any) => {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<{
    resetAt: number;
    data: any[];
  }>({
    resetAt: Date.now(),
    data: [],
  });
  const flightMappingRef = useRef<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");

  const fetchAIFlight = async () => {
    try {
      const res = await dispatch(
        getListAIFlightsThunk({
          origin_airport: payload.departFrom,
          destination_airport: payload.departTo,
          cabin: payload.cabinClass || "economy",
          start_date: payload.departStartDate || "",
          end_date: payload.departEndDate || "",
        })
      ).unwrap();

      const flights = Object.keys(res)?.reduce((prev: any, cur: any) => {
        if (!res[cur as unknown as string]?.length) {
          return prev;
        }

        const flightData = (
          res[cur as unknown as string]?.[0]?.extendData || []
        ).filter((data: any) => {
          const cabinClass = (payload.cabinClass || "economy").toLowerCase();
          return data.Cabin?.toLowerCase() === cabinClass;
        });

        if (!!res[cur as unknown as string]?.[0]?.extendData?.length) {
          return [...prev, ...flightData];
        }

        return [...prev];
      }, []);

      const uniqueFlights: any = [];
      flights.forEach((flight: any) => {
        const uniqueKey = generateFlightKey(flight);
        if (!uniqueKey || !!flightMappingRef.current[uniqueKey]) {
          return;
        }

        uniqueFlights.push(flight);
        flightMappingRef.current[uniqueKey] = true;
      });

      setResults((prev) => {
        return {
          resetAt: Date.now(),
          data: [...prev.data, ...uniqueFlights],
        };
      });
    } catch {}
  };

  const fetchPHFlights = async () => {
    try {
      const flightClass =
        FlightClassMapping[
          (payload.cabinClass || "economy").toLowerCase() as "economy"
        ];

      const resSearchId = await dispatch(
        getPHSearchIdThunk({
          departureDate: payload.departStartDate,
          destinationCode: payload.departTo,
          flightClass: flightClass,
          originCode: payload.departFrom,
          passengerCount: 1,
        })
      ).unwrap();

      if (resSearchId?.data?.data) {
        let callCount = 0;
        const idInterval = setInterval(async () => {
          try {
            callCount++;
            if (callCount >= 6) {
              clearInterval(idInterval);
            }
            const response = await dispatch(
              getPHFlightsThunk({
                flightClass: flightClass,
                searchId: resSearchId.data.data,
                passengerCount: 1,
                count: callCount,
              })
            ).unwrap();

            const uniqueFlights: any[] = [];
            if (response && response.data.length) {
              response.data.forEach((item: any) => {
                const transformedFlight = convertPHFlightToSAFlight(item);
                if (!!transformedFlight) {
                  const uniqueKey = generateFlightKey(transformedFlight);
                  if (!uniqueKey) return;

                  if (!flightMappingRef.current[uniqueKey!]) {
                    flightMappingRef.current[uniqueKey!] = true;
                    uniqueFlights.push(transformedFlight);
                  }
                }
              });
            }

            setResults((prev) => {
              return {
                resetAt: Date.now(),
                data: [...prev.data, ...uniqueFlights],
              };
            });

            if (callCount >= 6) {
              //   setIsPHFlightLoading(false);
            }
          } catch (e: any) {
            console.error("Error fetching flights:", e);
            // setIsPHFlightLoading(false);
            clearInterval(idInterval);
          }
        }, 500);
      }
    } catch {}
  };

  const fetFlights = () => {
    setIsLoading(true);
    setResults({
      resetAt: Date.now(),
      data: [],
    });
    setTimeout(async () => {
      flightMappingRef.current = [];
      await Promise.all([fetchAIFlight(), fetchPHFlights()]);
      setIsLoading(false);
    }, 1000);
  };

  const handleSortFlights = (sortOption: SortOption) => {
    if (sortOption.value === "random") {
      return;
    }

    const flights = [...results.data];
    if (sortOption.value === "lowest-points") {
      const sortedFlights = flights.sort(
        (a, b) => a.MileageCost - b.MileageCost
      );
      setResults({
        resetAt: Date.now(),
        data: sortedFlights,
      });
    }

    if (sortOption.value === "duration") {
      const sortedFlights = flights.sort(
        (a, b) => a.TotalDuration - b.TotalDuration
      );
      setResults({
        resetAt: Date.now(),
        data: sortedFlights,
      });
    }

    if (sortOption.value === "stops") {
      const sortedFlights = flights.sort(
        (a, b) =>
          a.AvailabilitySegments?.length - b.AvailabilitySegments?.length
      );
      setResults({
        resetAt: Date.now(),
        data: sortedFlights,
      });
    }
  };

  useEffect(() => {
    fetFlights();
  }, [payload]);

  useEffect(() => {
    if (results.data.length) {
      setIsLoading(false);
    }
  }, [results]);

  return (
    <WrapperResults>
      <FilterWrapper>
        <SearchComponent value={searchTerm} onChange={setSearchTerm} />
        <SSelect
          defaultValue={SortOptions[0]}
          options={SortOptions}
          onChange={(value: any) =>
            handleSortFlights(SortOptions.find((item) => item.value === value)!)
          }
          id="sort"
        />
      </FilterWrapper>
      {isLoading && <Spin />}
      {!isLoading && results.data.length === 0 && <div>No Flight Found</div>}
      {results.data.map((flight, index) => {
        if (
          !!searchTerm &&
          !getUniqueAirlineNames(flight?.offerFlightSegments || [])
            ?.join(', ')
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase())
        ) {
          return <></>;
        }

        return <FlightCard key={index} data={flight} />;
      })}
    </WrapperResults>
  );
};

export default FlightResults;
