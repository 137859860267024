import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "./index.css";
import AuthStepProvider from "./context/authStep";
import { ThemeProvider } from "styled-components";
import theme from "./context/theme";
import { TopicProvider } from "./context/topicContext";
import { NewChatProvider } from "./context/newChatContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const key =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_KEY_PROD
    : process.env.REACT_APP_STRIPE_KEY_TEST;
const rootElement = document.getElementById("root");

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <NewChatProvider>
      <GoogleOAuthProvider clientId="268459681545-t2f1ebhahtub5dmae8uegemsgmnjr587.apps.googleusercontent.com">
        <TopicProvider>
          <BrowserRouter>
            <Provider store={store}>
              <AuthStepProvider>
                <App />
              </AuthStepProvider>
            </Provider>
          </BrowserRouter>
        </TopicProvider>
      </GoogleOAuthProvider>
    </NewChatProvider>
  </ThemeProvider>,
  // </React.StrictMode>,
  rootElement
);
