import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
  Button,
} from "@mui/material";
import styled from "styled-components";
import { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { Appearance } from "@stripe/stripe-js";
import { useAppDispatch } from "../../store";
import { createSubscriptionThunk } from "../../store/actions/subscription.action";
import { ReactComponent as CheckCircle } from "../../assets/check.svg";
import { getUserInfoThunk } from "../../store/actions/auth.action";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const benefits = [
  "Unlimited 5-Star Hotel Special Rate, Available Year-Round (Blackout Dates and NLRA Apply)",
  "Points can be accrued through your existing hotel membership program (e.g., Hyatt, Marriott, IHG, etc.)",
  "Program and hotel event invitations are by invite only.",
  "All premium services provided by KardoAI.",
  "Hotel Wishlist Available",
];

const PaymentForm = ({ refresh }: { refresh: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubscribe = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { error: stripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (stripeError) {
        setError(
          stripeError.message ||
            "An error occurred while processing your payment."
        );
      } else {
        setSuccess(true);
      }
    } catch (err) {
      setError(
        "An error occurred while setting up your subscription. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (success) {
    return (
      <StyledCard>
        <SuccessIcon>✓</SuccessIcon>
        <SuccessTitle>Payment Successful!</SuccessTitle>
        <SuccessMessage>
          Thank you for your subscription. Your payment has been processed
          successfully.
        </SuccessMessage>
        <SButton onClick={refresh}>Go Back</SButton>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <PaymentWrapper>
        <PaymentElement />
      </PaymentWrapper>

      <SButton
        disabled={!stripe || !elements || isLoading}
        onClick={handleSubscribe}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Pay"}
      </SButton>

      <SButton onClick={refresh}>Back</SButton>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </StyledCard>
  );
};

const SubscriptionForm = () => {
  const dispatch = useAppDispatch();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const initializePayment = async () => {
    setIsLoading(true);
    try {
      const result = await dispatch(
        createSubscriptionThunk({
          paymentGateway: 2,
          paymentMode: 4,
        })
      ).unwrap();

      setClientSecret(result.clientSecret);
    } catch (err) {
      console.error("Failed to initialize payment:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance: Appearance = {
    theme: "night" as const,
    variables: {
      colorPrimary: "#3461ff",
      colorBackground: "#333",
      colorText: "#fff",
      colorDanger: "#ff4444",
      fontFamily: "system-ui, sans-serif",
      spacingUnit: "4px",
      borderRadius: "8px",
    },
  };

  const handleRefresh = async () => {
    await dispatch(getUserInfoThunk());
    setClientSecret(null);
  };

  return (
    <div
      style={{
        width: "100%",
        boxSizing: "border-box",
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {!clientSecret && (
        <div>
          <p style={{ fontWeight: 600, fontSize: 22, margin: 0 }}>
            Executive Tier
          </p>
          <p
            style={{ fontWeight: 400, fontSize: 14, margin: 0, marginTop: 10 }}
          >
            Get access to exclusive benefits and rewards
          </p>

          <PriceTag>
            <span className="amount">$499.99</span>
            <span className="period">/year</span>
          </PriceTag>

          <SButton onClick={initializePayment} disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Get Advanced"
            )}
          </SButton>

          <div style={{ marginTop: "1rem" }}>
            {benefits.map((benefit, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <CheckCircle width={20} />
                <p style={{ flex: 1 }}>{benefit}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ appearance, clientSecret }}>
          <PaymentForm refresh={handleRefresh} />
        </Elements>
      )}
    </div>
  );
};

const StyledCard = styled.div`
  color: white;
  width: 100%;
  border-radius: 16px;
`;

const PriceTag = styled.div`
  text-align: center;
  margin: 2rem 0 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .amount {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
  }

  .period {
    color: rgba(255, 255, 255, 0.7);
    margin-left: 0.5rem;
  }
`;

const PaymentWrapper = styled.div`
  border-radius: 8px;
`;

const SButton = styled(Button)`
  && {
    background: #3461ff;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 18px;
    line-height: 24px;
    border-radius: 8px;
    width: 100%;
    margin: 1rem auto 0;
    display: flex;
    justify-content: center;

    &:hover {
      background: #4070ff;
    }

    &.Mui-disabled {
      background: rgba(52, 97, 255, 0.5);
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const SuccessIcon = styled.div`
  width: 64px;
  height: 64px;
  background-color: #4caf50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  color: white;
  font-size: 32px;
`;

const SuccessTitle = styled.h2`
  color: white;
  margin: 0 0 1rem;
  text-align: center;
`;

const SuccessMessage = styled.p`
  color: #888;
  margin: 0 0 2rem;
  text-align: center;
`;

export default SubscriptionForm;
