import axios, { AxiosResponse } from "axios";

export const createQuestion = async (
  text: string,
  chat_id: string | null,
  need_bot: boolean,
  bot_role: string,
  booking_type: number,
) => {
  const res = await axios.post('/journey/ai-bot', {
    text,
    chat_id,
    need_bot,
    bot_role,
    booking_type,
  });
  return res;
};

export const getHistory = async (bookingType: number, page: number) => {
  const res = await axios.get(
    `/chat-msg/history?bookingType=${bookingType}&limit=20`,
  );
  return res;
};

export const getDetailHistory = async (id: string) => {
  const res = await axios.get(`/chat-msg/history/${id}`);
  return res;
};

export const getAnswer = async (id: string) => {
  const res = await axios.get(`/chat-msg/${id}`);
  return res;
};
