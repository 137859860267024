import { createReducer } from "@reduxjs/toolkit";
import {
  getBestCardsThunk,
  getListAIFlightsThunk,
  getMerchantsNearlyThunk,
  getPHFlightsThunk,
  getPHSearchIdThunk,
  searchAIHotelsThunk,
} from "../actions/journey.action";

export interface JourneyState {
  loading: boolean;
}

const initialState: JourneyState = {
  loading: false,
};

const JourneyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getListAIFlightsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getListAIFlightsThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getListAIFlightsThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getPHFlightsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getPHFlightsThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getPHFlightsThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getPHSearchIdThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getPHSearchIdThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getPHSearchIdThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(searchAIHotelsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(searchAIHotelsThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(searchAIHotelsThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getBestCardsThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getBestCardsThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getBestCardsThunk.rejected, (state) => {
      state.loading = false;
    });
  builder
    .addCase(getMerchantsNearlyThunk.pending, (state) => {
      state.loading = true;
    })
    .addCase(getMerchantsNearlyThunk.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getMerchantsNearlyThunk.rejected, (state) => {
      state.loading = false;
    });
});

export default JourneyReducer;
