import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  transition: width 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
`;

export const MotionWrapper = motion(Wrapper);

export const Container = styled.div`
  display: flex;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  width: 40%;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 90%;
    overflow: hidden;
  }
`;

export const MainContent = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const Logo = styled.img`
  height: 54px;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    height: 40px;
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled.h1`
  max-width: 600px;
  text-align: center;
  margin: 0 0 1rem;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    max-width: 100%;
  }
`;

export const Description = styled.p`
  max-width: 600px;
  text-align: center;
  color: #ccc;
  line-height: 24px;
  font-size: 14px;

  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 13px;
  }
`;

export const FeatureCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2rem 0 0;
  width: 100%;
  max-width: 650px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled(Link)`
  background-color: #424242;
  padding: 0.5rem;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.3s ease;

  svg {
    color: #00ff00;
    font-size: 1.5rem;
  }

  h2 {
    font-weight: 500;
  }

  :hover {
    background-color: #525252;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

export const FilterBar = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
`;

export const FilterButton = styled.button<{ active?: boolean }>`
  padding: 0.5rem 1rem;
  background: ${(props) => (props.active ? "#00ff00" : "transparent")};
  color: ${(props) => (props.active ? "#000" : "#fff")};
  border: none;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.active ? "#00ff00" : "#333")};
  }
`;

export const PromptInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;

  input {
    width: 100%;
    padding: 1rem 3rem;
    background-color: rgba(51, 51, 51, 0.8);
    border: none;
    border-radius: 30px;
    color: white;
    box-sizing: border-box;
    font-size: 16px;

    @media (max-width: 768px) {
      padding: 0.75rem 2.5rem;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #00ff00;
    }
  }
`;

export const SendButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #00cc00;
  }
`;

export const MapPreview = styled.div`
  width: 100%;
  height: 120px;
  background-color: #2a2a2a;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 14px;
`;

// Right side

export const RightSidebar = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? "0" : "-480px")};
  width: 480px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    right: ${(props) => (props.isOpen ? "0" : "-100%")};
  }
`;
export const SidebarCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const SidebarButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const BookingBlock = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 24px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const NewTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100px;
  height: 100px;
  z-index: 10;

  &:before {
    content: "NEW";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 30px;
    background: linear-gradient(45deg, #FF6B6B, #FF8E53);
    color: white;
    font-weight: bold;
    font-size: 14px;
    top: 24px;
    left: -36px;
    transform: rotate(-45deg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const BookingButton = styled.button`
  background: linear-gradient(45deg, #FF6B6B, #FF8E53);
  color: #fff;
  border: none;
  padding: 1.2rem 2.5rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0 4px 15px rgba(255, 107, 107, 0.3); */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(255, 107, 107, 0.4),
                0 0 20px rgba(255, 142, 83, 0.3);
    background: linear-gradient(45deg, #FF8E53, #FF6B6B);

    &:before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 2px 10px rgba(255, 107, 107, 0.3);
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;

export const ButtonText = styled.span`
  position: relative;
  z-index: 1;
`;

export const ButtonIcon = styled.span`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: rgba(0, 149, 255, 0.1);
  border-radius: 50%;
  padding: 4px;
  
  svg {
    width: 16px;
    height: 16px;
    color: #fff;
  }
`;

export const BookingTitle = styled.h1`
  text-align: center;
  color: #fff;
  margin: 0;
  width: 80%;
  font-size: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.75rem;
  }
`;

export const BookingDescription = styled.p`
  text-align: center;
  color: #ccc;
  line-height: 24px;
  font-size: 14px;
`;

export const BookingMapPreview = styled.img`
  width: 100%;
  height: 190px;
  background-color: #2a2a2a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 14px;
  margin-top: 32px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 150px;
    margin-top: 32px;
  }

  :hover {
    opacity: 0.8;
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  justify-content: center;

  @media (max-width: 1200px) {
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
